(function(){var h,aa=aa||{},k=this,m=function(a){return"string"==typeof a},ba=function(a){return"boolean"==typeof a},ca=function(a){return"number"==typeof a},da=function(){},ea=function(a){var b=typeof a;if("object"==b)if(a){if(a instanceof Array)return"array";if(a instanceof Object)return b;var c=Object.prototype.toString.call(a);if("[object Window]"==c)return"object";if("[object Array]"==c||"number"==typeof a.length&&"undefined"!=typeof a.splice&&"undefined"!=typeof a.propertyIsEnumerable&&!a.propertyIsEnumerable("splice"))return"array";
if("[object Function]"==c||"undefined"!=typeof a.call&&"undefined"!=typeof a.propertyIsEnumerable&&!a.propertyIsEnumerable("call"))return"function"}else return"null";else if("function"==b&&"undefined"==typeof a.call)return"object";return b},fa=function(a){return null===a},ga=function(a){return"array"==ea(a)},ha=function(a){var b=ea(a);return"array"==b||"object"==b&&"number"==typeof a.length},p=function(a){return"function"==ea(a)},ia=function(a){var b=typeof a;return"object"==b&&null!=a||"function"==
b},ja=function(a,b,c){return a.call.apply(a.bind,arguments)},ka=function(a,b,c){if(!a)throw Error();if(2<arguments.length){var d=Array.prototype.slice.call(arguments,2);return function(){var c=Array.prototype.slice.call(arguments);Array.prototype.unshift.apply(c,d);return a.apply(b,c)}}return function(){return a.apply(b,arguments)}},q=function(a,b,c){q=Function.prototype.bind&&-1!=Function.prototype.bind.toString().indexOf("native code")?ja:ka;return q.apply(null,arguments)},la=function(a,b){var c=
Array.prototype.slice.call(arguments,1);return function(){var b=c.slice();b.push.apply(b,arguments);return a.apply(this,b)}},ma=Date.now||function(){return+new Date},r=function(a,b){function c(){}c.prototype=b.prototype;a.Id=b.prototype;a.prototype=new c;a.prototype.constructor=a;a.ag=function(a,c,f){for(var d=Array(arguments.length-2),e=2;e<arguments.length;e++)d[e-2]=arguments[e];return b.prototype[c].apply(a,d)}};var t=function(a){if(Error.captureStackTrace)Error.captureStackTrace(this,t);else{var b=Error().stack;b&&(this.stack=b)}a&&(this.message=String(a))};r(t,Error);t.prototype.name="CustomError";var na=function(a,b){for(var c=a.split("%s"),d="",e=Array.prototype.slice.call(arguments,1);e.length&&1<c.length;)d+=c.shift()+e.shift();return d+c.join("%s")},oa=String.prototype.trim?function(a){return a.trim()}:function(a){return a.replace(/^[\s\xa0]+|[\s\xa0]+$/g,"")},wa=function(a){if(!pa.test(a))return a;-1!=a.indexOf("&")&&(a=a.replace(qa,"&amp;"));-1!=a.indexOf("<")&&(a=a.replace(ra,"&lt;"));-1!=a.indexOf(">")&&(a=a.replace(sa,"&gt;"));-1!=a.indexOf('"')&&(a=a.replace(ta,"&quot;"));-1!=a.indexOf("'")&&
(a=a.replace(ua,"&#39;"));-1!=a.indexOf("\x00")&&(a=a.replace(va,"&#0;"));return a},qa=/&/g,ra=/</g,sa=/>/g,ta=/"/g,ua=/'/g,va=/\x00/g,pa=/[\x00&<>"']/,u=function(a,b){return-1!=a.indexOf(b)},xa=function(a,b){return a<b?-1:a>b?1:0};var ya=function(a,b){b.unshift(a);t.call(this,na.apply(null,b));b.shift()};r(ya,t);ya.prototype.name="AssertionError";
var za=function(a,b,c,d){var e="Assertion failed";if(c){e+=": "+c;var f=d}else a&&(e+=": "+a,f=b);throw new ya(""+e,f||[]);},v=function(a,b,c){a||za("",null,b,Array.prototype.slice.call(arguments,2))},Aa=function(a,b){throw new ya("Failure"+(a?": "+a:""),Array.prototype.slice.call(arguments,1));},Ba=function(a,b,c){ca(a)||za("Expected number but got %s: %s.",[ea(a),a],b,Array.prototype.slice.call(arguments,2));return a},Ca=function(a,b,c){m(a)||za("Expected string but got %s: %s.",[ea(a),a],b,Array.prototype.slice.call(arguments,
2))},Da=function(a,b,c){p(a)||za("Expected function but got %s: %s.",[ea(a),a],b,Array.prototype.slice.call(arguments,2))};var Ea=Array.prototype.indexOf?function(a,b,c){v(null!=a.length);return Array.prototype.indexOf.call(a,b,c)}:function(a,b,c){c=null==c?0:0>c?Math.max(0,a.length+c):c;if(m(a))return m(b)&&1==b.length?a.indexOf(b,c):-1;for(;c<a.length;c++)if(c in a&&a[c]===b)return c;return-1},w=Array.prototype.forEach?function(a,b,c){v(null!=a.length);Array.prototype.forEach.call(a,b,c)}:function(a,b,c){for(var d=a.length,e=m(a)?a.split(""):a,f=0;f<d;f++)f in e&&b.call(c,e[f],f,a)},Fa=function(a,b){for(var c=m(a)?
a.split(""):a,d=a.length-1;0<=d;--d)d in c&&b.call(void 0,c[d],d,a)},Ga=Array.prototype.map?function(a,b,c){v(null!=a.length);return Array.prototype.map.call(a,b,c)}:function(a,b,c){for(var d=a.length,e=Array(d),f=m(a)?a.split(""):a,g=0;g<d;g++)g in f&&(e[g]=b.call(c,f[g],g,a));return e},Ha=Array.prototype.some?function(a,b,c){v(null!=a.length);return Array.prototype.some.call(a,b,c)}:function(a,b,c){for(var d=a.length,e=m(a)?a.split(""):a,f=0;f<d;f++)if(f in e&&b.call(c,e[f],f,a))return!0;return!1},
Ja=function(a){a:{var b=Ia;for(var c=a.length,d=m(a)?a.split(""):a,e=0;e<c;e++)if(e in d&&b.call(void 0,d[e],e,a)){b=e;break a}b=-1}return 0>b?null:m(a)?a.charAt(b):a[b]},Ka=function(a,b){return 0<=Ea(a,b)},Ma=function(a,b){b=Ea(a,b);var c;(c=0<=b)&&La(a,b);return c},La=function(a,b){v(null!=a.length);return 1==Array.prototype.splice.call(a,b,1).length},Na=function(a,b){var c=0;Fa(a,function(d,e){b.call(void 0,d,e,a)&&La(a,e)&&c++})},Oa=function(a){return Array.prototype.concat.apply([],arguments)},
Pa=function(a){var b=a.length;if(0<b){for(var c=Array(b),d=0;d<b;d++)c[d]=a[d];return c}return[]};var Qa=function(a){return Ga(a,function(a){a=a.toString(16);return 1<a.length?a:"0"+a}).join("")};var Ra;a:{var Sa=k.navigator;if(Sa){var Ta=Sa.userAgent;if(Ta){Ra=Ta;break a}}Ra=""}var x=function(a){return u(Ra,a)};var Ua=function(a,b){for(var c in a)b.call(void 0,a[c],c,a)},Va=function(a){var b=[],c=0,d;for(d in a)b[c++]=a[d];return b},Wa=function(a){var b=[],c=0,d;for(d in a)b[c++]=d;return b},Xa=function(a){for(var b in a)return!1;return!0},Ya=function(a,b){for(var c in a)if(!(c in b)||a[c]!==b[c])return!1;for(c in b)if(!(c in a))return!1;return!0},Za=function(a){var b={},c;for(c in a)b[c]=a[c];return b},$a="constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf".split(" "),
ab=function(a,b){for(var c,d,e=1;e<arguments.length;e++){d=arguments[e];for(c in d)a[c]=d[c];for(var f=0;f<$a.length;f++)c=$a[f],Object.prototype.hasOwnProperty.call(d,c)&&(a[c]=d[c])}};var bb=function(a){bb[" "](a);return a};bb[" "]=da;var db=function(a,b){var c=cb;return Object.prototype.hasOwnProperty.call(c,a)?c[a]:c[a]=b(a)};var eb=x("Opera"),y=x("Trident")||x("MSIE"),fb=x("Edge"),gb=fb||y,hb=x("Gecko")&&!(u(Ra.toLowerCase(),"webkit")&&!x("Edge"))&&!(x("Trident")||x("MSIE"))&&!x("Edge"),ib=u(Ra.toLowerCase(),"webkit")&&!x("Edge"),jb=function(){var a=k.document;return a?a.documentMode:void 0},kb;
a:{var mb="",nb=function(){var a=Ra;if(hb)return/rv\:([^\);]+)(\)|;)/.exec(a);if(fb)return/Edge\/([\d\.]+)/.exec(a);if(y)return/\b(?:MSIE|rv)[: ]([^\);]+)(\)|;)/.exec(a);if(ib)return/WebKit\/(\S+)/.exec(a);if(eb)return/(?:Version)[ \/]?(\S+)/.exec(a)}();nb&&(mb=nb?nb[1]:"");if(y){var ob=jb();if(null!=ob&&ob>parseFloat(mb)){kb=String(ob);break a}}kb=mb}
var pb=kb,cb={},z=function(a){return db(a,function(){for(var b=0,c=oa(String(pb)).split("."),d=oa(String(a)).split("."),e=Math.max(c.length,d.length),f=0;0==b&&f<e;f++){var g=c[f]||"",l=d[f]||"";do{g=/(\d*)(\D*)(.*)/.exec(g)||["","","",""];l=/(\d*)(\D*)(.*)/.exec(l)||["","","",""];if(0==g[0].length&&0==l[0].length)break;b=xa(0==g[1].length?0:parseInt(g[1],10),0==l[1].length?0:parseInt(l[1],10))||xa(0==g[2].length,0==l[2].length)||xa(g[2],l[2]);g=g[3];l=l[3]}while(0==b)}return 0<=b})},qb;var rb=k.document;
qb=rb&&y?jb()||("CSS1Compat"==rb.compatMode?parseInt(pb,10):5):void 0;var sb=null,tb=null,vb=function(a){var b="";ub(a,function(a){b+=String.fromCharCode(a)});return b},ub=function(a,b){function c(b){for(;d<a.length;){var c=a.charAt(d++),e=tb[c];if(null!=e)return e;if(!/^[\s\xa0]*$/.test(c))throw Error("Unknown base64 encoding at char: "+c);}return b}wb();for(var d=0;;){var e=c(-1),f=c(0),g=c(64),l=c(64);if(64===l&&-1===e)break;b(e<<2|f>>4);64!=g&&(b(f<<4&240|g>>2),64!=l&&b(g<<6&192|l))}},wb=function(){if(!sb){sb={};tb={};for(var a=0;65>a;a++)sb[a]="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".charAt(a),
tb[sb[a]]=a,62<=a&&(tb["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.".charAt(a)]=a)}};var xb=function(){this.Ea=-1};var Ab=function(a,b){this.Ea=64;this.kc=k.Uint8Array?new Uint8Array(this.Ea):Array(this.Ea);this.Qc=this.jb=0;this.i=[];this.uf=a;this.ge=b;this.Uf=k.Int32Array?new Int32Array(64):Array(64);void 0!==yb||(yb=k.Int32Array?new Int32Array(zb):zb);this.reset()},yb;r(Ab,xb);for(var Bb=[],Cb=0;63>Cb;Cb++)Bb[Cb]=0;var Db=Oa(128,Bb);Ab.prototype.reset=function(){this.Qc=this.jb=0;this.i=k.Int32Array?new Int32Array(this.ge):Pa(this.ge)};
var Eb=function(a){var b=a.kc;v(b.length==a.Ea);for(var c=a.Uf,d=0,e=0;e<b.length;)c[d++]=b[e]<<24|b[e+1]<<16|b[e+2]<<8|b[e+3],e=4*d;for(b=16;64>b;b++){d=c[b-15]|0;e=c[b-2]|0;e=(e>>>17|e<<15)^(e>>>19|e<<13)^e>>>10;var f=(c[b-16]|0)+((d>>>7|d<<25)^(d>>>18|d<<14)^d>>>3)|0;var g=(c[b-7]|0)+e|0;c[b]=f+g|0}var d=a.i[0]|0,e=a.i[1]|0,l=a.i[2]|0,n=a.i[3]|0,C=a.i[4]|0,lb=a.i[5]|0,Tb=a.i[6]|0;f=a.i[7]|0;for(b=0;64>b;b++){var Mh=((d>>>2|d<<30)^(d>>>13|d<<19)^(d>>>22|d<<10))+(d&e^d&l^e&l)|0;g=C&lb^~C&Tb;f=f+
((C>>>6|C<<26)^(C>>>11|C<<21)^(C>>>25|C<<7))|0;g=g+(yb[b]|0)|0;g=f+(g+(c[b]|0)|0)|0;f=Tb;Tb=lb;lb=C;C=n+g|0;n=l;l=e;e=d;d=g+Mh|0}a.i[0]=a.i[0]+d|0;a.i[1]=a.i[1]+e|0;a.i[2]=a.i[2]+l|0;a.i[3]=a.i[3]+n|0;a.i[4]=a.i[4]+C|0;a.i[5]=a.i[5]+lb|0;a.i[6]=a.i[6]+Tb|0;a.i[7]=a.i[7]+f|0};
Ab.prototype.update=function(a,b){void 0===b&&(b=a.length);var c=0,d=this.jb;if(m(a))for(;c<b;)this.kc[d++]=a.charCodeAt(c++),d==this.Ea&&(Eb(this),d=0);else if(ha(a))for(;c<b;){var e=a[c++];if(!("number"==typeof e&&0<=e&&255>=e&&e==(e|0)))throw Error("message must be a byte array");this.kc[d++]=e;d==this.Ea&&(Eb(this),d=0)}else throw Error("message must be string or array");this.jb=d;this.Qc+=b};
Ab.prototype.digest=function(){var a=[],b=8*this.Qc;56>this.jb?this.update(Db,56-this.jb):this.update(Db,this.Ea-(this.jb-56));for(var c=63;56<=c;c--)this.kc[c]=b&255,b/=256;Eb(this);for(c=b=0;c<this.uf;c++)for(var d=24;0<=d;d-=8)a[b++]=this.i[c]>>d&255;return a};
var zb=[1116352408,1899447441,3049323471,3921009573,961987163,1508970993,2453635748,2870763221,3624381080,310598401,607225278,1426881987,1925078388,2162078206,2614888103,3248222580,3835390401,4022224774,264347078,604807628,770255983,1249150122,1555081692,1996064986,2554220882,2821834349,2952996808,3210313671,3336571891,3584528711,113926993,338241895,666307205,773529912,1294757372,1396182291,1695183700,1986661051,2177026350,2456956037,2730485921,2820302411,3259730800,3345764771,3516065817,3600352804,
4094571909,275423344,430227734,506948616,659060556,883997877,958139571,1322822218,1537002063,1747873779,1955562222,2024104815,2227730452,2361852424,2428436474,2756734187,3204031479,3329325298];var Gb=function(){Ab.call(this,8,Fb)};r(Gb,Ab);var Fb=[1779033703,3144134277,1013904242,2773480762,1359893119,2600822924,528734635,1541459225];var Hb=function(){this.Ha=this.Ha;this.Cc=this.Cc};Hb.prototype.Ha=!1;Hb.prototype.isDisposed=function(){return this.Ha};Hb.prototype.gb=function(){if(this.Cc)for(;this.Cc.length;)this.Cc.shift()()};var Ib=!y||9<=Number(qb),Jb=y&&!z("9");!ib||z("528");hb&&z("1.9b")||y&&z("8")||eb&&z("9.5")||ib&&z("528");hb&&!z("8")||y&&z("9");var Kb=function(a,b){this.type=a;this.currentTarget=this.target=b;this.defaultPrevented=this.pb=!1;this.qe=!0};Kb.prototype.preventDefault=function(){this.defaultPrevented=!0;this.qe=!1};var Lb=function(a,b){Kb.call(this,a?a.type:"");this.relatedTarget=this.currentTarget=this.target=null;this.button=this.screenY=this.screenX=this.clientY=this.clientX=this.offsetY=this.offsetX=0;this.key="";this.charCode=this.keyCode=0;this.metaKey=this.shiftKey=this.altKey=this.ctrlKey=!1;this.Ia=this.state=null;a&&this.init(a,b)};r(Lb,Kb);
Lb.prototype.init=function(a,b){var c=this.type=a.type,d=a.changedTouches?a.changedTouches[0]:null;this.target=a.target||a.srcElement;this.currentTarget=b;if(b=a.relatedTarget){if(hb){a:{try{bb(b.nodeName);var e=!0;break a}catch(f){}e=!1}e||(b=null)}}else"mouseover"==c?b=a.fromElement:"mouseout"==c&&(b=a.toElement);this.relatedTarget=b;null===d?(this.offsetX=ib||void 0!==a.offsetX?a.offsetX:a.layerX,this.offsetY=ib||void 0!==a.offsetY?a.offsetY:a.layerY,this.clientX=void 0!==a.clientX?a.clientX:a.pageX,
this.clientY=void 0!==a.clientY?a.clientY:a.pageY,this.screenX=a.screenX||0,this.screenY=a.screenY||0):(this.clientX=void 0!==d.clientX?d.clientX:d.pageX,this.clientY=void 0!==d.clientY?d.clientY:d.pageY,this.screenX=d.screenX||0,this.screenY=d.screenY||0);this.button=a.button;this.keyCode=a.keyCode||0;this.key=a.key||"";this.charCode=a.charCode||("keypress"==c?a.keyCode:0);this.ctrlKey=a.ctrlKey;this.altKey=a.altKey;this.shiftKey=a.shiftKey;this.metaKey=a.metaKey;this.state=a.state;this.Ia=a;a.defaultPrevented&&
this.preventDefault()};Lb.prototype.preventDefault=function(){Lb.Id.preventDefault.call(this);var a=this.Ia;if(a.preventDefault)a.preventDefault();else if(a.returnValue=!1,Jb)try{if(a.ctrlKey||112<=a.keyCode&&123>=a.keyCode)a.keyCode=-1}catch(b){}};Lb.prototype.Ze=function(){return this.Ia};var Mb="closure_listenable_"+(1E6*Math.random()|0),Nb=0;var Ob=function(a,b,c,d,e){this.listener=a;this.Gc=null;this.src=b;this.type=c;this.capture=!!d;this.qc=e;this.key=++Nb;this.tb=this.jc=!1},Pb=function(a){a.tb=!0;a.listener=null;a.Gc=null;a.src=null;a.qc=null};var Qb=function(a){this.src=a;this.J={};this.ec=0};Qb.prototype.add=function(a,b,c,d,e){var f=a.toString();a=this.J[f];a||(a=this.J[f]=[],this.ec++);var g=Rb(a,b,d,e);-1<g?(b=a[g],c||(b.jc=!1)):(b=new Ob(b,this.src,f,!!d,e),b.jc=c,a.push(b));return b};Qb.prototype.remove=function(a,b,c,d){a=a.toString();if(!(a in this.J))return!1;var e=this.J[a];b=Rb(e,b,c,d);return-1<b?(Pb(e[b]),La(e,b),0==e.length&&(delete this.J[a],this.ec--),!0):!1};
var Sb=function(a,b){var c=b.type;c in a.J&&Ma(a.J[c],b)&&(Pb(b),0==a.J[c].length&&(delete a.J[c],a.ec--))};Qb.prototype.fd=function(a,b,c,d){a=this.J[a.toString()];var e=-1;a&&(e=Rb(a,b,c,d));return-1<e?a[e]:null};var Rb=function(a,b,c,d){for(var e=0;e<a.length;++e){var f=a[e];if(!f.tb&&f.listener==b&&f.capture==!!c&&f.qc==d)return e}return-1};var Ub="closure_lm_"+(1E6*Math.random()|0),Vb={},Wb=0,Xb=function(a,b,c,d,e){if(ga(b))for(var f=0;f<b.length;f++)Xb(a,b[f],c,d,e);else c=Yb(c),a&&a[Mb]?a.listen(b,c,d,e):Zb(a,b,c,!1,d,e)},Zb=function(a,b,c,d,e,f){if(!b)throw Error("Invalid event type");var g=!!e,l=$b(a);l||(a[Ub]=l=new Qb(a));c=l.add(b,c,d,e,f);if(!c.Gc){d=ac();c.Gc=d;d.src=a;d.listener=c;if(a.addEventListener)a.addEventListener(b.toString(),d,g);else if(a.attachEvent)a.attachEvent(bc(b.toString()),d);else throw Error("addEventListener and attachEvent are unavailable.");
Wb++}},ac=function(){var a=cc,b=Ib?function(c){return a.call(b.src,b.listener,c)}:function(c){c=a.call(b.src,b.listener,c);if(!c)return c};return b},dc=function(a,b,c,d,e){if(ga(b))for(var f=0;f<b.length;f++)dc(a,b[f],c,d,e);else c=Yb(c),a&&a[Mb]?ec(a,b,c,d,e):Zb(a,b,c,!0,d,e)},fc=function(a,b,c,d,e){if(ga(b))for(var f=0;f<b.length;f++)fc(a,b[f],c,d,e);else c=Yb(c),a&&a[Mb]?a.ja.remove(String(b),c,d,e):a&&(a=$b(a))&&(b=a.fd(b,c,!!d,e))&&gc(b)},gc=function(a){if(!ca(a)&&a&&!a.tb){var b=a.src;if(b&&
b[Mb])Sb(b.ja,a);else{var c=a.type,d=a.Gc;b.removeEventListener?b.removeEventListener(c,d,a.capture):b.detachEvent&&b.detachEvent(bc(c),d);Wb--;(c=$b(b))?(Sb(c,a),0==c.ec&&(c.src=null,b[Ub]=null)):Pb(a)}}},bc=function(a){return a in Vb?Vb[a]:Vb[a]="on"+a},ic=function(a,b,c,d){var e=!0;if(a=$b(a))if(b=a.J[b.toString()])for(b=b.concat(),a=0;a<b.length;a++){var f=b[a];f&&f.capture==c&&!f.tb&&(f=hc(f,d),e=e&&!1!==f)}return e},hc=function(a,b){var c=a.listener,d=a.qc||a.src;a.jc&&gc(a);return c.call(d,
b)},cc=function(a,b){if(a.tb)return!0;if(!Ib){if(!b)a:{b=["window","event"];for(var c=k,d;d=b.shift();)if(null!=c[d])c=c[d];else{b=null;break a}b=c}d=b;b=new Lb(d,this);c=!0;if(!(0>d.keyCode||void 0!=d.returnValue)){a:{var e=!1;if(0==d.keyCode)try{d.keyCode=-1;break a}catch(g){e=!0}if(e||void 0==d.returnValue)d.returnValue=!0}d=[];for(e=b.currentTarget;e;e=e.parentNode)d.push(e);for(var e=a.type,f=d.length-1;!b.pb&&0<=f;f--)b.currentTarget=d[f],a=ic(d[f],e,!0,b),c=c&&a;for(f=0;!b.pb&&f<d.length;f++)b.currentTarget=
d[f],a=ic(d[f],e,!1,b),c=c&&a}return c}return hc(a,new Lb(b,this))},$b=function(a){a=a[Ub];return a instanceof Qb?a:null},jc="__closure_events_fn_"+(1E9*Math.random()>>>0),Yb=function(a){v(a,"Listener can not be null.");if(p(a))return a;v(a.handleEvent,"An object listener must have handleEvent method.");a[jc]||(a[jc]=function(b){return a.handleEvent(b)});return a[jc]};var kc=/^[+a-zA-Z0-9_.!#$%&'*\/=?^`{|}~-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z0-9]{2,63}$/;var mc=function(){this.Mc="";this.Ge=lc};mc.prototype.Ib=!0;mc.prototype.Fb=function(){return this.Mc};mc.prototype.toString=function(){return"Const{"+this.Mc+"}"};var nc=function(a){if(a instanceof mc&&a.constructor===mc&&a.Ge===lc)return a.Mc;Aa("expected object of type Const, got '"+a+"'");return"type_error:Const"},lc={},oc=function(a){var b=new mc;b.Mc=a;return b};oc("");var qc=function(){this.Fc="";this.He=pc};qc.prototype.Ib=!0;qc.prototype.Fb=function(){return this.Fc};qc.prototype.toString=function(){return"TrustedResourceUrl{"+this.Fc+"}"};var rc=function(a){if(a instanceof qc&&a.constructor===qc&&a.He===pc)return a.Fc;Aa("expected object of type TrustedResourceUrl, got '"+a+"' of type "+ea(a));return"type_error:TrustedResourceUrl"},pc={};var tc=function(){this.qa="";this.Fe=sc};tc.prototype.Ib=!0;tc.prototype.Fb=function(){return this.qa};tc.prototype.toString=function(){return"SafeUrl{"+this.qa+"}"};
var uc=function(a){if(a instanceof tc&&a.constructor===tc&&a.Fe===sc)return a.qa;Aa("expected object of type SafeUrl, got '"+a+"' of type "+ea(a));return"type_error:SafeUrl"},vc=/^(?:(?:https?|mailto|ftp):|[^:/?#]*(?:[/?#]|$))/i,xc=function(a){if(a instanceof tc)return a;a=a.Ib?a.Fb():String(a);vc.test(a)||(a="about:invalid#zClosurez");return wc(a)},sc={},wc=function(a){var b=new tc;b.qa=a;return b};wc("about:blank");var Ac=function(a){var b=[];yc(new zc,a,b);return b.join("")},zc=function(){this.Hc=void 0},yc=function(a,b,c){if(null==b)c.push("null");else{if("object"==typeof b){if(ga(b)){var d=b;b=d.length;c.push("[");for(var e="",f=0;f<b;f++)c.push(e),e=d[f],yc(a,a.Hc?a.Hc.call(d,String(f),e):e,c),e=",";c.push("]");return}if(b instanceof String||b instanceof Number||b instanceof Boolean)b=b.valueOf();else{c.push("{");f="";for(d in b)Object.prototype.hasOwnProperty.call(b,d)&&(e=b[d],"function"!=typeof e&&(c.push(f),
Bc(d,c),c.push(":"),yc(a,a.Hc?a.Hc.call(b,d,e):e,c),f=","));c.push("}");return}}switch(typeof b){case "string":Bc(b,c);break;case "number":c.push(isFinite(b)&&!isNaN(b)?String(b):"null");break;case "boolean":c.push(String(b));break;case "function":c.push("null");break;default:throw Error("Unknown type: "+typeof b);}}},Cc={'"':'\\"',"\\":"\\\\","/":"\\/","\b":"\\b","\f":"\\f","\n":"\\n","\r":"\\r","\t":"\\t","\x0B":"\\u000b"},Dc=/\uffff/.test("\uffff")?/[\\\"\x00-\x1f\x7f-\uffff]/g:/[\\\"\x00-\x1f\x7f-\xff]/g,
Bc=function(a,b){b.push('"',a.replace(Dc,function(a){var b=Cc[a];b||(b="\\u"+(a.charCodeAt(0)|65536).toString(16).substr(1),Cc[a]=b);return b}),'"')};var Ec=function(){};Ec.prototype.Nd=null;var Fc=function(a){return a.Nd||(a.Nd=a.md())};var Gc,Hc=function(){};r(Hc,Ec);Hc.prototype.lc=function(){var a=Ic(this);return a?new ActiveXObject(a):new XMLHttpRequest};Hc.prototype.md=function(){var a={};Ic(this)&&(a[0]=!0,a[1]=!0);return a};
var Ic=function(a){if(!a.fe&&"undefined"==typeof XMLHttpRequest&&"undefined"!=typeof ActiveXObject){for(var b=["MSXML2.XMLHTTP.6.0","MSXML2.XMLHTTP.3.0","MSXML2.XMLHTTP","Microsoft.XMLHTTP"],c=0;c<b.length;c++){var d=b[c];try{return new ActiveXObject(d),a.fe=d}catch(e){}}throw Error("Could not create ActiveXObject. ActiveX might be disabled, or MSXML might not be installed");}return a.fe};Gc=new Hc;var Jc=function(){};r(Jc,Ec);Jc.prototype.lc=function(){var a=new XMLHttpRequest;if("withCredentials"in a)return a;if("undefined"!=typeof XDomainRequest)return new Kc;throw Error("Unsupported browser");};Jc.prototype.md=function(){return{}};
var Kc=function(){this.va=new XDomainRequest;this.readyState=0;this.onreadystatechange=null;this.responseText="";this.status=-1;this.statusText=this.responseXML=null;this.va.onload=q(this.af,this);this.va.onerror=q(this.be,this);this.va.onprogress=q(this.bf,this);this.va.ontimeout=q(this.cf,this)};h=Kc.prototype;h.open=function(a,b,c){if(null!=c&&!c)throw Error("Only async requests are supported.");this.va.open(a,b)};
h.send=function(a){if(a)if("string"==typeof a)this.va.send(a);else throw Error("Only string data is supported");else this.va.send()};h.abort=function(){this.va.abort()};h.setRequestHeader=function(){};h.af=function(){this.status=200;this.responseText=this.va.responseText;Lc(this,4)};h.be=function(){this.status=500;this.responseText="";Lc(this,4)};h.cf=function(){this.be()};h.bf=function(){this.status=200;Lc(this,1)};var Lc=function(a,b){a.readyState=b;if(a.onreadystatechange)a.onreadystatechange()};var Mc=function(a,b,c){this.pf=c;this.Oe=a;this.Ef=b;this.Bc=0;this.rc=null};Mc.prototype.get=function(){if(0<this.Bc){this.Bc--;var a=this.rc;this.rc=a.next;a.next=null}else a=this.Oe();return a};Mc.prototype.put=function(a){this.Ef(a);this.Bc<this.pf&&(this.Bc++,a.next=this.rc,this.rc=a)};var Nc=function(a){k.setTimeout(function(){throw a;},0)},Oc,Pc=function(){var a=k.MessageChannel;"undefined"===typeof a&&"undefined"!==typeof window&&window.postMessage&&window.addEventListener&&!x("Presto")&&(a=function(){var a=document.createElement("IFRAME");a.style.display="none";a.src="";document.documentElement.appendChild(a);var b=a.contentWindow,a=b.document;a.open();a.write("");a.close();var c="callImmediate"+Math.random(),d="file:"==b.location.protocol?"*":b.location.protocol+"//"+b.location.host,
a=q(function(a){if(("*"==d||a.origin==d)&&a.data==c)this.port1.onmessage()},this);b.addEventListener("message",a,!1);this.port1={};this.port2={postMessage:function(){b.postMessage(c,d)}}});if("undefined"!==typeof a&&!x("Trident")&&!x("MSIE")){var b=new a,c={},d=c;b.port1.onmessage=function(){if(void 0!==c.next){c=c.next;var a=c.Qd;c.Qd=null;a()}};return function(a){d.next={Qd:a};d=d.next;b.port2.postMessage(0)}}return"undefined"!==typeof document&&"onreadystatechange"in document.createElement("SCRIPT")?
function(a){var b=document.createElement("SCRIPT");b.onreadystatechange=function(){b.onreadystatechange=null;b.parentNode.removeChild(b);b=null;a();a=null};document.documentElement.appendChild(b)}:function(a){k.setTimeout(a,0)}};var Qc=function(){this.Vc=this.bb=null},Sc=new Mc(function(){return new Rc},function(a){a.reset()},100);Qc.prototype.add=function(a,b){var c=Sc.get();c.set(a,b);this.Vc?this.Vc.next=c:(v(!this.bb),this.bb=c);this.Vc=c};Qc.prototype.remove=function(){var a=null;this.bb&&(a=this.bb,this.bb=this.bb.next,this.bb||(this.Vc=null),a.next=null);return a};var Rc=function(){this.next=this.scope=this.ed=null};Rc.prototype.set=function(a,b){this.ed=a;this.scope=b;this.next=null};
Rc.prototype.reset=function(){this.next=this.scope=this.ed=null};var Xc=function(a,b){Tc||Uc();Vc||(Tc(),Vc=!0);Wc.add(a,b)},Tc,Uc=function(){if(-1!=String(k.Promise).indexOf("[native code]")){var a=k.Promise.resolve(void 0);Tc=function(){a.then(Yc)}}else Tc=function(){var a=Yc;!p(k.setImmediate)||k.Window&&k.Window.prototype&&!x("Edge")&&k.Window.prototype.setImmediate==k.setImmediate?(Oc||(Oc=Pc()),Oc(a)):k.setImmediate(a)}},Vc=!1,Wc=new Qc,Yc=function(){for(var a;a=Wc.remove();){try{a.ed.call(a.scope)}catch(b){Nc(b)}Sc.put(a)}Vc=!1};var Zc=function(a){return ia(a)?a.constructor.displayName||a.constructor.name||Object.prototype.toString.call(a):void 0===a?"undefined":null===a?"null":typeof a};var $c=!y||9<=Number(qb);!hb&&!y||y&&9<=Number(qb)||hb&&z("1.9.1");y&&z("9");var bd=function(){this.qa="";this.Ee=ad};bd.prototype.Ib=!0;bd.prototype.Fb=function(){return this.qa};bd.prototype.toString=function(){return"SafeHtml{"+this.qa+"}"};var cd=function(a){if(a instanceof bd&&a.constructor===bd&&a.Ee===ad)return a.qa;Aa("expected object of type SafeHtml, got '"+a+"' of type "+ea(a));return"type_error:SafeHtml"},ad={};bd.prototype.jf=function(a){this.qa=a;return this};var dd=function(a,b){"undefined"!=typeof HTMLScriptElement&&"undefined"!=typeof Element&&v(a&&(a instanceof HTMLScriptElement||!(a instanceof Element)),"Argument is not a HTMLScriptElement (or a non-Element mock); got: %s",Zc(a));a.src=rc(b)};var ed=function(a){var b=document;return m(a)?b.getElementById(a):a},gd=function(a,b){Ua(b,function(b,d){b&&b.Ib&&(b=b.Fb());"style"==d?a.style.cssText=b:"class"==d?a.className=b:"for"==d?a.htmlFor=b:fd.hasOwnProperty(d)?a.setAttribute(fd[d],b):0==d.lastIndexOf("aria-",0)||0==d.lastIndexOf("data-",0)?a.setAttribute(d,b):a[d]=b})},fd={cellpadding:"cellPadding",cellspacing:"cellSpacing",colspan:"colSpan",frameborder:"frameBorder",height:"height",maxlength:"maxLength",nonce:"nonce",role:"role",rowspan:"rowSpan",
type:"type",usemap:"useMap",valign:"vAlign",width:"width"},id=function(a,b,c){var d=arguments,e=document,f=String(d[0]),g=d[1];if(!$c&&g&&(g.name||g.type)){f=["<",f];g.name&&f.push(' name="',wa(g.name),'"');if(g.type){f.push(' type="',wa(g.type),'"');var l={};ab(l,g);delete l.type;g=l}f.push(">");f=f.join("")}f=e.createElement(f);g&&(m(g)?f.className=g:ga(g)?f.className=g.join(" "):gd(f,g));2<d.length&&hd(e,f,d);return f},hd=function(a,b,c){function d(c){c&&b.appendChild(m(c)?a.createTextNode(c):
c)}for(var e=2;e<c.length;e++){var f=c[e];!ha(f)||ia(f)&&0<f.nodeType?d(f):w(jd(f)?Pa(f):f,d)}},jd=function(a){if(a&&"number"==typeof a.length){if(ia(a))return"function"==typeof a.item||"string"==typeof a.item;if(p(a))return"function"==typeof a.item}return!1};var kd=function(a){a.prototype.then=a.prototype.then;a.prototype.$goog_Thenable=!0},ld=function(a){if(!a)return!1;try{return!!a.$goog_Thenable}catch(b){return!1}};var A=function(a,b){this.W=0;this.sa=void 0;this.fb=this.oa=this.u=null;this.pc=this.dd=!1;if(a!=da)try{var c=this;a.call(b,function(a){md(c,2,a)},function(a){if(!(a instanceof nd))try{if(a instanceof Error)throw a;throw Error("Promise rejected.");}catch(e){}md(c,3,a)})}catch(d){md(this,3,d)}},od=function(){this.next=this.context=this.lb=this.Qa=this.child=null;this.yb=!1};od.prototype.reset=function(){this.context=this.lb=this.Qa=this.child=null;this.yb=!1};
var pd=new Mc(function(){return new od},function(a){a.reset()},100),qd=function(a,b,c){var d=pd.get();d.Qa=a;d.lb=b;d.context=c;return d},B=function(a){if(a instanceof A)return a;var b=new A(da);md(b,2,a);return b},D=function(a){return new A(function(b,c){c(a)})},sd=function(a,b,c){rd(a,b,c,null)||Xc(la(b,a))},td=function(a){return new A(function(b){var c=a.length,d=[];if(c)for(var e=function(a,e,f){c--;d[a]=e?{Xe:!0,value:f}:{Xe:!1,reason:f};0==c&&b(d)},f=0,g;f<a.length;f++)g=a[f],sd(g,la(e,f,!0),
la(e,f,!1));else b(d)})};A.prototype.then=function(a,b,c){null!=a&&Da(a,"opt_onFulfilled should be a function.");null!=b&&Da(b,"opt_onRejected should be a function. Did you pass opt_context as the second argument instead of the third?");return ud(this,p(a)?a:null,p(b)?b:null,c)};kd(A);var wd=function(a,b){b=qd(b,b,void 0);b.yb=!0;vd(a,b);return a};A.prototype.f=function(a,b){return ud(this,null,a,b)};A.prototype.cancel=function(a){0==this.W&&Xc(function(){var b=new nd(a);xd(this,b)},this)};
var xd=function(a,b){if(0==a.W)if(a.u){var c=a.u;if(c.oa){for(var d=0,e=null,f=null,g=c.oa;g&&(g.yb||(d++,g.child==a&&(e=g),!(e&&1<d)));g=g.next)e||(f=g);e&&(0==c.W&&1==d?xd(c,b):(f?(d=f,v(c.oa),v(null!=d),d.next==c.fb&&(c.fb=d),d.next=d.next.next):yd(c),zd(c,e,3,b)))}a.u=null}else md(a,3,b)},vd=function(a,b){a.oa||2!=a.W&&3!=a.W||Ad(a);v(null!=b.Qa);a.fb?a.fb.next=b:a.oa=b;a.fb=b},ud=function(a,b,c,d){var e=qd(null,null,null);e.child=new A(function(a,g){e.Qa=b?function(c){try{var e=b.call(d,c);a(e)}catch(C){g(C)}}:
a;e.lb=c?function(b){try{var e=c.call(d,b);void 0===e&&b instanceof nd?g(b):a(e)}catch(C){g(C)}}:g});e.child.u=a;vd(a,e);return e.child};A.prototype.Rf=function(a){v(1==this.W);this.W=0;md(this,2,a)};A.prototype.Sf=function(a){v(1==this.W);this.W=0;md(this,3,a)};
var md=function(a,b,c){0==a.W&&(a===c&&(b=3,c=new TypeError("Promise cannot resolve to itself")),a.W=1,rd(c,a.Rf,a.Sf,a)||(a.sa=c,a.W=b,a.u=null,Ad(a),3!=b||c instanceof nd||Bd(a,c)))},rd=function(a,b,c,d){if(a instanceof A)return null!=b&&Da(b,"opt_onFulfilled should be a function."),null!=c&&Da(c,"opt_onRejected should be a function. Did you pass opt_context as the second argument instead of the third?"),vd(a,qd(b||da,c||null,d)),!0;if(ld(a))return a.then(b,c,d),!0;if(ia(a))try{var e=a.then;if(p(e))return Cd(a,
e,b,c,d),!0}catch(f){return c.call(d,f),!0}return!1},Cd=function(a,b,c,d,e){var f=!1,g=function(a){f||(f=!0,c.call(e,a))},l=function(a){f||(f=!0,d.call(e,a))};try{b.call(a,g,l)}catch(n){l(n)}},Ad=function(a){a.dd||(a.dd=!0,Xc(a.Te,a))},yd=function(a){var b=null;a.oa&&(b=a.oa,a.oa=b.next,b.next=null);a.oa||(a.fb=null);null!=b&&v(null!=b.Qa);return b};A.prototype.Te=function(){for(var a;a=yd(this);)zd(this,a,this.W,this.sa);this.dd=!1};
var zd=function(a,b,c,d){if(3==c&&b.lb&&!b.yb)for(;a&&a.pc;a=a.u)a.pc=!1;if(b.child)b.child.u=null,Dd(b,c,d);else try{b.yb?b.Qa.call(b.context):Dd(b,c,d)}catch(e){Ed.call(null,e)}pd.put(b)},Dd=function(a,b,c){2==b?a.Qa.call(a.context,c):a.lb&&a.lb.call(a.context,c)},Bd=function(a,b){a.pc=!0;Xc(function(){a.pc&&Ed.call(null,b)})},Ed=Nc,nd=function(a){t.call(this,a)};r(nd,t);nd.prototype.name="cancel";
var Fd=function(a,b){this.Jc=[];this.le=a;this.Ud=b||null;this.Gb=this.hb=!1;this.sa=void 0;this.Fd=this.Ld=this.Zc=!1;this.Rc=0;this.u=null;this.$c=0};Fd.prototype.cancel=function(a){if(this.hb)this.sa instanceof Fd&&this.sa.cancel();else{if(this.u){var b=this.u;delete this.u;a?b.cancel(a):(b.$c--,0>=b.$c&&b.cancel())}this.le?this.le.call(this.Ud,this):this.Fd=!0;this.hb||Gd(this,new Hd)}};Fd.prototype.Sd=function(a,b){this.Zc=!1;Id(this,a,b)};
var Id=function(a,b,c){a.hb=!0;a.sa=c;a.Gb=!b;Jd(a)},Ld=function(a){if(a.hb){if(!a.Fd)throw new Kd;a.Fd=!1}};Fd.prototype.callback=function(a){Ld(this);Md(a);Id(this,!0,a)};
var Gd=function(a,b){Ld(a);Md(b);Id(a,!1,b)},Md=function(a){v(!(a instanceof Fd),"An execution sequence may not be initiated with a blocking Deferred.")},Qd=function(a){var b=Nd("https://apis.google.com/js/client.js?onload="+Od);Pd(b,null,a,void 0)},Pd=function(a,b,c,d){v(!a.Ld,"Blocking Deferreds can not be re-used");a.Jc.push([b,c,d]);a.hb&&Jd(a)};Fd.prototype.then=function(a,b,c){var d,e,f=new A(function(a,b){d=a;e=b});Pd(this,d,function(a){a instanceof Hd?f.cancel():e(a)});return f.then(a,b,c)};
kd(Fd);
var Rd=function(a){return Ha(a.Jc,function(a){return p(a[1])})},Jd=function(a){if(a.Rc&&a.hb&&Rd(a)){var b=a.Rc,c=Sd[b];c&&(k.clearTimeout(c.Hb),delete Sd[b]);a.Rc=0}a.u&&(a.u.$c--,delete a.u);for(var b=a.sa,d=c=!1;a.Jc.length&&!a.Zc;){var e=a.Jc.shift(),f=e[0],g=e[1],e=e[2];if(f=a.Gb?g:f)try{var l=f.call(e||a.Ud,b);void 0!==l&&(a.Gb=a.Gb&&(l==b||l instanceof Error),a.sa=b=l);if(ld(b)||"function"===typeof k.Promise&&b instanceof k.Promise)d=!0,a.Zc=!0}catch(n){b=n,a.Gb=!0,Rd(a)||(c=!0)}}a.sa=b;d&&
(l=q(a.Sd,a,!0),d=q(a.Sd,a,!1),b instanceof Fd?(Pd(b,l,d),b.Ld=!0):b.then(l,d));c&&(b=new Td(b),Sd[b.Hb]=b,a.Rc=b.Hb)},Kd=function(){t.call(this)};r(Kd,t);Kd.prototype.message="Deferred has already fired";Kd.prototype.name="AlreadyCalledError";var Hd=function(){t.call(this)};r(Hd,t);Hd.prototype.message="Deferred was canceled";Hd.prototype.name="CanceledError";var Td=function(a){this.Hb=k.setTimeout(q(this.Qf,this),0);this.X=a};
Td.prototype.Qf=function(){v(Sd[this.Hb],"Cannot throw an error that is not scheduled.");delete Sd[this.Hb];throw this.X;};var Sd={};var Nd=function(a){var b=new qc;b.Fc=a;return Ud(b)},Ud=function(a){var b={},c=b.document||document,d=rc(a),e=document.createElement("SCRIPT"),f={re:e,dc:void 0},g=new Fd(Vd,f),l=null,n=null!=b.timeout?b.timeout:5E3;0<n&&(l=window.setTimeout(function(){Wd(e,!0);Gd(g,new Xd(1,"Timeout reached for loading script "+d))},n),f.dc=l);e.onload=e.onreadystatechange=function(){e.readyState&&"loaded"!=e.readyState&&"complete"!=e.readyState||(Wd(e,b.bg||!1,l),g.callback(null))};e.onerror=function(){Wd(e,!0,
l);Gd(g,new Xd(0,"Error while loading script "+d))};f=b.attributes||{};ab(f,{type:"text/javascript",charset:"UTF-8"});gd(e,f);dd(e,a);Yd(c).appendChild(e);return g},Yd=function(a){var b;return(b=(a||document).getElementsByTagName("HEAD"))&&0!=b.length?b[0]:a.documentElement},Vd=function(){if(this&&this.re){var a=this.re;a&&"SCRIPT"==a.tagName&&Wd(a,!0,this.dc)}},Wd=function(a,b,c){null!=c&&k.clearTimeout(c);a.onload=da;a.onerror=da;a.onreadystatechange=da;b&&window.setTimeout(function(){a&&a.parentNode&&
a.parentNode.removeChild(a)},0)},Xd=function(a,b){var c="Jsloader error (code #"+a+")";b&&(c+=": "+b);t.call(this,c);this.code=a};r(Xd,t);var Zd=function(a,b,c,d,e){this.reset(a,b,c,d,e)};Zd.prototype.Wd=null;var $d=0;Zd.prototype.reset=function(a,b,c,d,e){"number"==typeof e||$d++;d||ma();this.Nb=a;this.sf=b;delete this.Wd};Zd.prototype.te=function(a){this.Nb=a};var ae=function(a){this.tf=a;this.ce=this.ad=this.Nb=this.u=null},be=function(a,b){this.name=a;this.value=b};be.prototype.toString=function(){return this.name};var ce=new be("SEVERE",1E3),de=new be("CONFIG",700),ee=new be("FINE",500);ae.prototype.getParent=function(){return this.u};ae.prototype.te=function(a){this.Nb=a};var fe=function(a){if(a.Nb)return a.Nb;if(a.u)return fe(a.u);Aa("Root logger has no level set.");return null};
ae.prototype.log=function(a,b,c){if(a.value>=fe(this).value)for(p(b)&&(b=b()),a=new Zd(a,String(b),this.tf),c&&(a.Wd=c),c="log:"+a.sf,k.console&&(k.console.timeStamp?k.console.timeStamp(c):k.console.markTimeline&&k.console.markTimeline(c)),k.msWriteProfilerMark&&k.msWriteProfilerMark(c),c=this;c;){var d=c,e=a;if(d.ce)for(var f=0;b=d.ce[f];f++)b(e);c=c.getParent()}};
var ge={},he=null,ie=function(a){he||(he=new ae(""),ge[""]=he,he.te(de));var b;if(!(b=ge[a])){b=new ae(a);var c=a.lastIndexOf("."),d=a.substr(c+1),c=ie(a.substr(0,c));c.ad||(c.ad={});c.ad[d]=b;b.u=c;ge[a]=b}return b};var je=function(){Hb.call(this);this.ja=new Qb(this);this.Je=this;this.rd=null};r(je,Hb);je.prototype[Mb]=!0;h=je.prototype;h.addEventListener=function(a,b,c,d){Xb(this,a,b,c,d)};h.removeEventListener=function(a,b,c,d){fc(this,a,b,c,d)};
h.dispatchEvent=function(a){ke(this);var b=this.rd;if(b){var c=[];for(var d=1;b;b=b.rd)c.push(b),v(1E3>++d,"infinite loop")}b=this.Je;d=a.type||a;if(m(a))a=new Kb(a,b);else if(a instanceof Kb)a.target=a.target||b;else{var e=a;a=new Kb(d,b);ab(a,e)}var e=!0;if(c)for(var f=c.length-1;!a.pb&&0<=f;f--){var g=a.currentTarget=c[f];e=le(g,d,!0,a)&&e}a.pb||(g=a.currentTarget=b,e=le(g,d,!0,a)&&e,a.pb||(e=le(g,d,!1,a)&&e));if(c)for(f=0;!a.pb&&f<c.length;f++)g=a.currentTarget=c[f],e=le(g,d,!1,a)&&e;return e};
h.gb=function(){je.Id.gb.call(this);if(this.ja){var a=this.ja,b=0,c;for(c in a.J){for(var d=a.J[c],e=0;e<d.length;e++)++b,Pb(d[e]);delete a.J[c];a.ec--}}this.rd=null};h.listen=function(a,b,c,d){ke(this);return this.ja.add(String(a),b,!1,c,d)};
var ec=function(a,b,c,d,e){a.ja.add(String(b),c,!0,d,e)},le=function(a,b,c,d){b=a.ja.J[String(b)];if(!b)return!0;b=b.concat();for(var e=!0,f=0;f<b.length;++f){var g=b[f];if(g&&!g.tb&&g.capture==c){var l=g.listener,n=g.qc||g.src;g.jc&&Sb(a.ja,g);e=!1!==l.call(n,d)&&e}}return e&&0!=d.qe};je.prototype.fd=function(a,b,c,d){return this.ja.fd(String(a),b,c,d)};var ke=function(a){v(a.ja,"Event target is not initialized. Did you call the superclass (goog.events.EventTarget) constructor?")};var me="StopIteration"in k?k.StopIteration:{message:"StopIteration",stack:""},ne=function(){};ne.prototype.next=function(){throw me;};ne.prototype.Ie=function(){return this};var E=function(a,b){a&&a.log(ee,b,void 0)};var oe=function(a,b){this.ea={};this.A=[];this.ab=this.o=0;var c=arguments.length;if(1<c){if(c%2)throw Error("Uneven number of arguments");for(var d=0;d<c;d+=2)this.set(arguments[d],arguments[d+1])}else a&&this.addAll(a)};h=oe.prototype;h.da=function(){pe(this);for(var a=[],b=0;b<this.A.length;b++)a.push(this.ea[this.A[b]]);return a};h.pa=function(){pe(this);return this.A.concat()};h.Ab=function(a){return qe(this.ea,a)};h.clear=function(){this.ea={};this.ab=this.o=this.A.length=0};
h.remove=function(a){return qe(this.ea,a)?(delete this.ea[a],this.o--,this.ab++,this.A.length>2*this.o&&pe(this),!0):!1};var pe=function(a){var b,c;if(a.o!=a.A.length){for(b=c=0;c<a.A.length;){var d=a.A[c];qe(a.ea,d)&&(a.A[b++]=d);c++}a.A.length=b}if(a.o!=a.A.length){var e={};for(b=c=0;c<a.A.length;)d=a.A[c],qe(e,d)||(a.A[b++]=d,e[d]=1),c++;a.A.length=b}};h=oe.prototype;h.get=function(a,b){return qe(this.ea,a)?this.ea[a]:b};
h.set=function(a,b){qe(this.ea,a)||(this.o++,this.A.push(a),this.ab++);this.ea[a]=b};h.addAll=function(a){if(a instanceof oe){var b=a.pa();a=a.da()}else b=Wa(a),a=Va(a);for(var c=0;c<b.length;c++)this.set(b[c],a[c])};h.forEach=function(a,b){for(var c=this.pa(),d=0;d<c.length;d++){var e=c[d],f=this.get(e);a.call(b,f,e,this)}};h.clone=function(){return new oe(this)};
h.Ie=function(a){pe(this);var b=0,c=this.ab,d=this,e=new ne;e.next=function(){if(c!=d.ab)throw Error("The map has changed since the iterator was created");if(b>=d.A.length)throw me;var e=d.A[b++];return a?e:d.ea[e]};return e};var qe=function(a,b){return Object.prototype.hasOwnProperty.call(a,b)};var re=function(a){if(a.da&&"function"==typeof a.da)return a.da();if(m(a))return a.split("");if(ha(a)){for(var b=[],c=a.length,d=0;d<c;d++)b.push(a[d]);return b}return Va(a)},se=function(a){if(a.pa&&"function"==typeof a.pa)return a.pa();if(!a.da||"function"!=typeof a.da){if(ha(a)||m(a)){var b=[];a=a.length;for(var c=0;c<a;c++)b.push(c);return b}return Wa(a)}},te=function(a,b){if(a.forEach&&"function"==typeof a.forEach)a.forEach(b,void 0);else if(ha(a)||m(a))w(a,b,void 0);else for(var c=se(a),d=re(a),
e=d.length,f=0;f<e;f++)b.call(void 0,d[f],c&&c[f],a)};var ue=function(a,b,c){if(p(a))c&&(a=q(a,c));else if(a&&"function"==typeof a.handleEvent)a=q(a.handleEvent,a);else throw Error("Invalid listener argument");return 2147483647<Number(b)?-1:k.setTimeout(a,b||0)},ve=function(a){var b=null;return(new A(function(c,d){b=ue(function(){c(void 0)},a);-1==b&&d(Error("Failed to schedule timer."))})).f(function(a){k.clearTimeout(b);throw a;})};var we=/^(?:([^:/?#.]+):)?(?:\/\/(?:([^/?#]*)@)?([^/#?]*?)(?::([0-9]+))?(?=[/#?]|$))?([^?#]+)?(?:\?([^#]*))?(?:#([\s\S]*))?$/,xe=function(a,b){if(a){a=a.split("&");for(var c=0;c<a.length;c++){var d=a[c].indexOf("="),e=null;if(0<=d){var f=a[c].substring(0,d);e=a[c].substring(d+1)}else f=a[c];b(f,e?decodeURIComponent(e.replace(/\+/g," ")):"")}}};var F=function(a){je.call(this);this.headers=new oe;this.Xc=a||null;this.wa=!1;this.Wc=this.c=null;this.Mb=this.je=this.yc="";this.Ma=this.kd=this.uc=this.cd=!1;this.ub=0;this.Oc=null;this.Ic="";this.Sc=this.Af=this.De=!1};r(F,je);var ye=F.prototype,ze=ie("goog.net.XhrIo");ye.K=ze;var Ae=/^https?$/i,Be=["POST","PUT"];
F.prototype.send=function(a,b,c,d){if(this.c)throw Error("[goog.net.XhrIo] Object is active with another request="+this.yc+"; newUri="+a);b=b?b.toUpperCase():"GET";this.yc=a;this.Mb="";this.je=b;this.cd=!1;this.wa=!0;this.c=this.Xc?this.Xc.lc():Gc.lc();this.Wc=this.Xc?Fc(this.Xc):Fc(Gc);this.c.onreadystatechange=q(this.ne,this);this.Af&&"onprogress"in this.c&&(this.c.onprogress=q(function(a){this.me(a,!0)},this),this.c.upload&&(this.c.upload.onprogress=q(this.me,this)));try{E(this.K,Ce(this,"Opening Xhr")),
this.kd=!0,this.c.open(b,String(a),!0),this.kd=!1}catch(f){E(this.K,Ce(this,"Error opening Xhr: "+f.message));this.X(5,f);return}a=c||"";var e=this.headers.clone();d&&te(d,function(a,b){e.set(b,a)});d=Ja(e.pa());c=k.FormData&&a instanceof k.FormData;!Ka(Be,b)||d||c||e.set("Content-Type","application/x-www-form-urlencoded;charset=utf-8");e.forEach(function(a,b){this.c.setRequestHeader(b,a)},this);this.Ic&&(this.c.responseType=this.Ic);"withCredentials"in this.c&&this.c.withCredentials!==this.De&&(this.c.withCredentials=
this.De);try{De(this),0<this.ub&&(this.Sc=Ee(this.c),E(this.K,Ce(this,"Will abort after "+this.ub+"ms if incomplete, xhr2 "+this.Sc)),this.Sc?(this.c.timeout=this.ub,this.c.ontimeout=q(this.dc,this)):this.Oc=ue(this.dc,this.ub,this)),E(this.K,Ce(this,"Sending request")),this.uc=!0,this.c.send(a),this.uc=!1}catch(f){E(this.K,Ce(this,"Send error: "+f.message)),this.X(5,f)}};var Ee=function(a){return y&&z(9)&&ca(a.timeout)&&void 0!==a.ontimeout},Ia=function(a){return"content-type"==a.toLowerCase()};
F.prototype.dc=function(){"undefined"!=typeof aa&&this.c&&(this.Mb="Timed out after "+this.ub+"ms, aborting",E(this.K,Ce(this,this.Mb)),this.dispatchEvent("timeout"),this.abort(8))};F.prototype.X=function(a,b){this.wa=!1;this.c&&(this.Ma=!0,this.c.abort(),this.Ma=!1);this.Mb=b;Fe(this);Ge(this)};var Fe=function(a){a.cd||(a.cd=!0,a.dispatchEvent("complete"),a.dispatchEvent("error"))};
F.prototype.abort=function(){this.c&&this.wa&&(E(this.K,Ce(this,"Aborting")),this.wa=!1,this.Ma=!0,this.c.abort(),this.Ma=!1,this.dispatchEvent("complete"),this.dispatchEvent("abort"),Ge(this))};F.prototype.gb=function(){this.c&&(this.wa&&(this.wa=!1,this.Ma=!0,this.c.abort(),this.Ma=!1),Ge(this,!0));F.Id.gb.call(this)};F.prototype.ne=function(){this.isDisposed()||(this.kd||this.uc||this.Ma?He(this):this.wf())};F.prototype.wf=function(){He(this)};
var He=function(a){if(a.wa&&"undefined"!=typeof aa)if(a.Wc[1]&&4==Ie(a)&&2==Je(a))E(a.K,Ce(a,"Local request error detected and ignored"));else if(a.uc&&4==Ie(a))ue(a.ne,0,a);else if(a.dispatchEvent("readystatechange"),4==Ie(a)){E(a.K,Ce(a,"Request complete"));a.wa=!1;try{var b=Je(a);a:switch(b){case 200:case 201:case 202:case 204:case 206:case 304:case 1223:var c=!0;break a;default:c=!1}var d;if(!(d=c)){var e;if(e=0===b){var f=String(a.yc).match(we)[1]||null;if(!f&&k.self&&k.self.location)var g=k.self.location.protocol,
f=g.substr(0,g.length-1);e=!Ae.test(f?f.toLowerCase():"")}d=e}if(d)a.dispatchEvent("complete"),a.dispatchEvent("success");else{try{var l=2<Ie(a)?a.c.statusText:""}catch(n){E(a.K,"Can not get status: "+n.message),l=""}a.Mb=l+" ["+Je(a)+"]";Fe(a)}}finally{Ge(a)}}};F.prototype.me=function(a,b){v("progress"===a.type,"goog.net.EventType.PROGRESS is of the same type as raw XHR progress.");this.dispatchEvent(Ke(a,"progress"));this.dispatchEvent(Ke(a,b?"downloadprogress":"uploadprogress"))};
var Ke=function(a,b){return{type:b,lengthComputable:a.lengthComputable,loaded:a.loaded,total:a.total}},Ge=function(a,b){if(a.c){De(a);var c=a.c,d=a.Wc[0]?da:null;a.c=null;a.Wc=null;b||a.dispatchEvent("ready");try{c.onreadystatechange=d}catch(e){(a=a.K)&&a.log(ce,"Problem encountered resetting onreadystatechange: "+e.message,void 0)}}},De=function(a){a.c&&a.Sc&&(a.c.ontimeout=null);ca(a.Oc)&&(k.clearTimeout(a.Oc),a.Oc=null)},Ie=function(a){return a.c?a.c.readyState:0},Je=function(a){try{return 2<Ie(a)?
a.c.status:-1}catch(b){return-1}},Le=function(a){try{return a.c?a.c.responseText:""}catch(b){return E(a.K,"Can not get responseText: "+b.message),""}};
F.prototype.getResponse=function(){try{if(!this.c)return null;if("response"in this.c)return this.c.response;switch(this.Ic){case "":case "text":return this.c.responseText;case "arraybuffer":if("mozResponseArrayBuffer"in this.c)return this.c.mozResponseArrayBuffer}var a=this.K;a&&a.log(ce,"Response type "+this.Ic+" is not supported on this browser",void 0);return null}catch(b){return E(this.K,"Can not get response: "+b.message),null}};var Ce=function(a,b){return b+" ["+a.je+" "+a.yc+" "+Je(a)+"]"};var G=function(a,b){this.ia=this.Za=this.ka="";this.nb=null;this.La=this.za="";this.Z=this.nf=!1;if(a instanceof G){this.Z=void 0!==b?b:a.Z;Me(this,a.ka);var c=a.Za;H(this);this.Za=c;Ne(this,a.ia);Oe(this,a.nb);Pe(this,a.za);Qe(this,a.ba.clone());a=a.La;H(this);this.La=a}else a&&(c=String(a).match(we))?(this.Z=!!b,Me(this,c[1]||"",!0),a=c[2]||"",H(this),this.Za=Re(a),Ne(this,c[3]||"",!0),Oe(this,c[4]),Pe(this,c[5]||"",!0),Qe(this,c[6]||"",!0),a=c[7]||"",H(this),this.La=Re(a)):(this.Z=!!b,this.ba=
new I(null,0,this.Z))};G.prototype.toString=function(){var a=[],b=this.ka;b&&a.push(Se(b,Te,!0),":");var c=this.ia;if(c||"file"==b)a.push("//"),(b=this.Za)&&a.push(Se(b,Te,!0),"@"),a.push(encodeURIComponent(String(c)).replace(/%25([0-9a-fA-F]{2})/g,"%$1")),c=this.nb,null!=c&&a.push(":",String(c));if(c=this.za)this.ia&&"/"!=c.charAt(0)&&a.push("/"),a.push(Se(c,"/"==c.charAt(0)?Ue:Ve,!0));(c=this.ba.toString())&&a.push("?",c);(c=this.La)&&a.push("#",Se(c,We));return a.join("")};
G.prototype.resolve=function(a){var b=this.clone(),c=!!a.ka;c?Me(b,a.ka):c=!!a.Za;if(c){var d=a.Za;H(b);b.Za=d}else c=!!a.ia;c?Ne(b,a.ia):c=null!=a.nb;d=a.za;if(c)Oe(b,a.nb);else if(c=!!a.za){if("/"!=d.charAt(0))if(this.ia&&!this.za)d="/"+d;else{var e=b.za.lastIndexOf("/");-1!=e&&(d=b.za.substr(0,e+1)+d)}e=d;if(".."==e||"."==e)d="";else if(u(e,"./")||u(e,"/.")){for(var d=0==e.lastIndexOf("/",0),e=e.split("/"),f=[],g=0;g<e.length;){var l=e[g++];"."==l?d&&g==e.length&&f.push(""):".."==l?((1<f.length||
1==f.length&&""!=f[0])&&f.pop(),d&&g==e.length&&f.push("")):(f.push(l),d=!0)}d=f.join("/")}else d=e}c?Pe(b,d):c=""!==a.ba.toString();c?Qe(b,a.ba.clone()):c=!!a.La;c&&(a=a.La,H(b),b.La=a);return b};G.prototype.clone=function(){return new G(this)};
var Me=function(a,b,c){H(a);a.ka=c?Re(b,!0):b;a.ka&&(a.ka=a.ka.replace(/:$/,""))},Ne=function(a,b,c){H(a);a.ia=c?Re(b,!0):b},Oe=function(a,b){H(a);if(b){b=Number(b);if(isNaN(b)||0>b)throw Error("Bad port number "+b);a.nb=b}else a.nb=null},Pe=function(a,b,c){H(a);a.za=c?Re(b,!0):b},Qe=function(a,b,c){H(a);b instanceof I?(a.ba=b,a.ba.Dd(a.Z)):(c||(b=Se(b,Xe)),a.ba=new I(b,0,a.Z))},J=function(a,b,c){H(a);a.ba.set(b,c)},Ye=function(a,b){return a.ba.get(b)};
G.prototype.removeParameter=function(a){H(this);this.ba.remove(a);return this};var H=function(a){if(a.nf)throw Error("Tried to modify a read-only Uri");};G.prototype.Dd=function(a){this.Z=a;this.ba&&this.ba.Dd(a);return this};
var Ze=function(a){return a instanceof G?a.clone():new G(a,void 0)},$e=function(a,b){var c=new G(null,void 0);Me(c,"https");a&&Ne(c,a);b&&Pe(c,b);return c},Re=function(a,b){return a?b?decodeURI(a.replace(/%25/g,"%2525")):decodeURIComponent(a):""},Se=function(a,b,c){return m(a)?(a=encodeURI(a).replace(b,af),c&&(a=a.replace(/%25([0-9a-fA-F]{2})/g,"%$1")),a):null},af=function(a){a=a.charCodeAt(0);return"%"+(a>>4&15).toString(16)+(a&15).toString(16)},Te=/[#\/\?@]/g,Ve=/[\#\?:]/g,Ue=/[\#\?]/g,Xe=/[\#\?@]/g,
We=/#/g,I=function(a,b,c){this.o=this.j=null;this.P=a||null;this.Z=!!c},bf=function(a){a.j||(a.j=new oe,a.o=0,a.P&&xe(a.P,function(b,c){a.add(decodeURIComponent(b.replace(/\+/g," ")),c)}))},df=function(a){var b=se(a);if("undefined"==typeof b)throw Error("Keys are undefined");var c=new I(null,0,void 0);a=re(a);for(var d=0;d<b.length;d++){var e=b[d],f=a[d];ga(f)?cf(c,e,f):c.add(e,f)}return c};h=I.prototype;
h.add=function(a,b){bf(this);this.P=null;a=this.Y(a);var c=this.j.get(a);c||this.j.set(a,c=[]);c.push(b);this.o=Ba(this.o)+1;return this};h.remove=function(a){bf(this);a=this.Y(a);return this.j.Ab(a)?(this.P=null,this.o=Ba(this.o)-this.j.get(a).length,this.j.remove(a)):!1};h.clear=function(){this.j=this.P=null;this.o=0};h.Ab=function(a){bf(this);a=this.Y(a);return this.j.Ab(a)};h.forEach=function(a,b){bf(this);this.j.forEach(function(c,d){w(c,function(c){a.call(b,c,d,this)},this)},this)};
h.pa=function(){bf(this);for(var a=this.j.da(),b=this.j.pa(),c=[],d=0;d<b.length;d++)for(var e=a[d],f=0;f<e.length;f++)c.push(b[d]);return c};h.da=function(a){bf(this);var b=[];if(m(a))this.Ab(a)&&(b=Oa(b,this.j.get(this.Y(a))));else{a=this.j.da();for(var c=0;c<a.length;c++)b=Oa(b,a[c])}return b};h.set=function(a,b){bf(this);this.P=null;a=this.Y(a);this.Ab(a)&&(this.o=Ba(this.o)-this.j.get(a).length);this.j.set(a,[b]);this.o=Ba(this.o)+1;return this};
h.get=function(a,b){a=a?this.da(a):[];return 0<a.length?String(a[0]):b};var cf=function(a,b,c){a.remove(b);0<c.length&&(a.P=null,a.j.set(a.Y(b),Pa(c)),a.o=Ba(a.o)+c.length)};I.prototype.toString=function(){if(this.P)return this.P;if(!this.j)return"";for(var a=[],b=this.j.pa(),c=0;c<b.length;c++)for(var d=b[c],e=encodeURIComponent(String(d)),d=this.da(d),f=0;f<d.length;f++){var g=e;""!==d[f]&&(g+="="+encodeURIComponent(String(d[f])));a.push(g)}return this.P=a.join("&")};
I.prototype.clone=function(){var a=new I;a.P=this.P;this.j&&(a.j=this.j.clone(),a.o=this.o);return a};I.prototype.Y=function(a){a=String(a);this.Z&&(a=a.toLowerCase());return a};I.prototype.Dd=function(a){a&&!this.Z&&(bf(this),this.P=null,this.j.forEach(function(a,c){var b=c.toLowerCase();c!=b&&(this.remove(c),cf(this,b,a))},this));this.Z=a};var ef=function(){var a=K();return y&&!!qb&&11==qb||/Edge\/\d+/.test(a)},ff=function(){return k.window&&k.window.location.href||""},gf=function(a,b){b=b||k.window;var c="about:blank";a&&(c=uc(xc(a)));b.location.href=c},hf=function(a,b){var c=[],d;for(d in a)d in b?typeof a[d]!=typeof b[d]?c.push(d):ga(a[d])?Ya(a[d],b[d])||c.push(d):"object"==typeof a[d]&&null!=a[d]&&null!=b[d]?0<hf(a[d],b[d]).length&&c.push(d):a[d]!==b[d]&&c.push(d):c.push(d);for(d in b)d in a||c.push(d);return c},kf=function(){var a=
K();a="Chrome"!=jf(a)?null:(a=a.match(/\sChrome\/(\d+)/i))&&2==a.length?parseInt(a[1],10):null;return a&&30>a?!1:!y||!qb||9<qb},lf=function(a){a=(a||K()).toLowerCase();return a.match(/android/)||a.match(/webos/)||a.match(/iphone|ipad|ipod/)||a.match(/blackberry/)||a.match(/windows phone/)||a.match(/iemobile/)?!0:!1},mf=function(a){a=a||k.window;try{a.close()}catch(b){}},nf=function(a,b,c){var d=Math.floor(1E9*Math.random()).toString();b=b||500;c=c||600;var e=(window.screen.availHeight-c)/2,f=(window.screen.availWidth-
b)/2;b={width:b,height:c,top:0<e?e:0,left:0<f?f:0,location:!0,resizable:!0,statusbar:!0,toolbar:!1};c=K().toLowerCase();d&&(b.target=d,u(c,"crios/")&&(b.target="_blank"));"Firefox"==jf(K())&&(a=a||"http://localhost",b.scrollbars=!0);c=a||"about:blank";(d=b)||(d={});a=window;b=c instanceof tc?c:xc("undefined"!=typeof c.href?c.href:String(c));c=d.target||c.target;e=[];for(g in d)switch(g){case "width":case "height":case "top":case "left":e.push(g+"="+d[g]);break;case "target":case "noreferrer":break;
default:e.push(g+"="+(d[g]?1:0))}var g=e.join(",");(x("iPhone")&&!x("iPod")&&!x("iPad")||x("iPad")||x("iPod"))&&a.navigator&&a.navigator.standalone&&c&&"_self"!=c?(g=a.document.createElement("A"),"undefined"!=typeof HTMLAnchorElement&&"undefined"!=typeof Location&&"undefined"!=typeof Element&&v(g&&(g instanceof HTMLAnchorElement||!(g instanceof Location||g instanceof Element)),"Argument is not a HTMLAnchorElement (or a non-Element mock); got: %s",Zc(g)),b=b instanceof tc?b:xc(b),g.href=uc(b),g.setAttribute("target",
c),d.noreferrer&&g.setAttribute("rel","noreferrer"),d=document.createEvent("MouseEvent"),d.initMouseEvent("click",!0,!0,a,1),g.dispatchEvent(d),g={}):d.noreferrer?(g=a.open("",c,g),d=uc(b),g&&(gb&&u(d,";")&&(d="'"+d.replace(/'/g,"%27")+"'"),g.opener=null,a=oc("b/12014412, meta tag with sanitized URL"),d='<META HTTP-EQUIV="refresh" content="0; url='+wa(d)+'">',Ca(nc(a),"must provide justification"),v(!/^[\s\xa0]*$/.test(nc(a)),"must provide non-empty justification"),g.document.write(cd((new bd).jf(d))),
g.document.close())):g=a.open(uc(b),c,g);if(g)try{g.focus()}catch(l){}return g},of=function(a){return new A(function(b){var c=function(){ve(2E3).then(function(){if(!a||a.closed)b();else return c()})};return c()})},pf=/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/,qf=function(){var a=null;return(new A(function(b){"complete"==k.document.readyState?b():(a=function(){b()},dc(window,"load",a))})).f(function(b){fc(window,"load",a);throw b;})},sf=function(){return rf(void 0)?qf().then(function(){return new A(function(a,
b){var c=k.document,d=setTimeout(function(){b(Error("Cordova framework is not ready."))},1E3);c.addEventListener("deviceready",function(){clearTimeout(d);a()},!1)})}):D(Error("Cordova must run in an Android or iOS file scheme."))},rf=function(a){a=a||K();return!("file:"!==tf()||!a.toLowerCase().match(/iphone|ipad|ipod|android/))},uf=function(){var a=k.window;try{return!(!a||a==a.top)}catch(b){return!1}},vf=function(){return firebase.INTERNAL.hasOwnProperty("reactNative")?"ReactNative":firebase.INTERNAL.hasOwnProperty("node")?
"Node":"Browser"},wf=function(){var a=vf();return"ReactNative"===a||"Node"===a},jf=function(a){var b=a.toLowerCase();if(u(b,"opera/")||u(b,"opr/")||u(b,"opios/"))return"Opera";if(u(b,"iemobile"))return"IEMobile";if(u(b,"msie")||u(b,"trident/"))return"IE";if(u(b,"edge/"))return"Edge";if(u(b,"firefox/"))return"Firefox";if(u(b,"silk/"))return"Silk";if(u(b,"blackberry"))return"Blackberry";if(u(b,"webos"))return"Webos";if(!u(b,"safari/")||u(b,"chrome/")||u(b,"crios/")||u(b,"android"))if(!u(b,"chrome/")&&
!u(b,"crios/")||u(b,"edge/")){if(u(b,"android"))return"Android";if((a=a.match(/([a-zA-Z\d\.]+)\/[a-zA-Z\d\.]*$/))&&2==a.length)return a[1]}else return"Chrome";else return"Safari";return"Other"},xf=function(a){var b=vf();return("Browser"===b?jf(K()):b)+"/JsCore/"+a},K=function(){return k.navigator&&k.navigator.userAgent||""},L=function(a,b){a=a.split(".");b=b||k;for(var c=0;c<a.length&&"object"==typeof b&&null!=b;c++)b=b[a[c]];c!=a.length&&(b=void 0);return b},Af=function(){var a;if(a=(yf()||"chrome-extension:"===
tf()||rf())&&!wf())a:{try{var b=k.localStorage,c=zf();if(b){b.setItem(c,"1");b.removeItem(c);a=ef()?!!k.indexedDB:!0;break a}}catch(d){}a=!1}return a},yf=function(){return"http:"===tf()||"https:"===tf()},tf=function(){return k.location&&k.location.protocol||null},Bf=function(a){a=a||K();return lf(a)||"Firefox"==jf(a)?!1:!0},Cf=function(a){return"undefined"===typeof a?null:Ac(a)},Df=function(a){var b={},c;for(c in a)a.hasOwnProperty(c)&&null!==a[c]&&void 0!==a[c]&&(b[c]=a[c]);return b},Ef=function(a){if(null!==
a)return JSON.parse(a)},zf=function(a){return a?a:""+Math.floor(1E9*Math.random()).toString()},Ff=function(a){a=a||K();return"Safari"==jf(a)||a.toLowerCase().match(/iphone|ipad|ipod/)?!1:!0},Gf=function(){var a=k.___jsl;if(a&&a.H)for(var b in a.H)if(a.H[b].r=a.H[b].r||[],a.H[b].L=a.H[b].L||[],a.H[b].r=a.H[b].L.concat(),a.CP)for(var c=0;c<a.CP.length;c++)a.CP[c]=null},Hf=function(){return k.navigator&&"boolean"===typeof k.navigator.onLine?k.navigator.onLine:!0},If=function(a,b,c,d){if(a>b)throw Error("Short delay should be less than long delay!");
this.Nf=a;this.rf=b;a=c||K();d=d||vf();this.mf=lf(a)||"ReactNative"===d};If.prototype.get=function(){return this.mf?this.rf:this.Nf};
var Jf=function(){var a=k.document;return a&&"undefined"!==typeof a.visibilityState?"visible"==a.visibilityState:!0},Kf=function(){var a=k.document,b=null;return Jf()||!a?B():(new A(function(c){b=function(){Jf()&&(a.removeEventListener("visibilitychange",b,!1),c())};a.addEventListener("visibilitychange",b,!1)})).f(function(c){a.removeEventListener("visibilitychange",b,!1);throw c;})};var Lf={};var Mf;try{var Nf={};Object.defineProperty(Nf,"abcd",{configurable:!0,enumerable:!0,value:1});Object.defineProperty(Nf,"abcd",{configurable:!0,enumerable:!0,value:2});Mf=2==Nf.abcd}catch(a){Mf=!1}
var M=function(a,b,c){Mf?Object.defineProperty(a,b,{configurable:!0,enumerable:!0,value:c}):a[b]=c},Of=function(a,b){if(b)for(var c in b)b.hasOwnProperty(c)&&M(a,c,b[c])},Pf=function(a){var b={};Of(b,a);return b},Qf=function(a){var b={},c;for(c in a)a.hasOwnProperty(c)&&(b[c]=a[c]);return b},Rf=function(a,b){if(!b||!b.length)return!0;if(!a)return!1;for(var c=0;c<b.length;c++){var d=a[b[c]];if(void 0===d||null===d||""===d)return!1}return!0},Sf=function(a){var b=a;if("object"==typeof a&&null!=a){var b=
"length"in a?[]:{},c;for(c in a)M(b,c,Sf(a[c]))}return b};var Tf="oauth_consumer_key oauth_nonce oauth_signature oauth_signature_method oauth_timestamp oauth_token oauth_version".split(" "),Uf=["client_id","response_type","scope","redirect_uri","state"],Vf={Xf:{Rb:500,Qb:600,providerId:"facebook.com",zd:Uf},Yf:{Rb:500,Qb:620,providerId:"github.com",zd:Uf},Zf:{Rb:515,Qb:680,providerId:"google.com",zd:Uf},$f:{Rb:485,Qb:705,providerId:"twitter.com",zd:Tf}},Wf=function(a){for(var b in Vf)if(Vf[b].providerId==a)return Vf[b];return null};var N=function(a,b){this.code="auth/"+a;this.message=b||Xf[a]||""};r(N,Error);N.prototype.F=function(){return{code:this.code,message:this.message}};N.prototype.toJSON=function(){return this.F()};
var Yf=function(a){var b=a&&a.code;return b?new N(b.substring(5),a.message):null},Xf={"argument-error":"","app-not-authorized":"This app, identified by the domain where it's hosted, is not authorized to use Firebase Authentication with the provided API key. Review your key configuration in the Google API console.","app-not-installed":"The requested mobile application corresponding to the identifier (Android package name or iOS bundle ID) provided is not installed on this device.","captcha-check-failed":"The reCAPTCHA response token provided is either invalid, expired, already used or the domain associated with it does not match the list of whitelisted domains.",
"code-expired":"The SMS code has expired. Please re-send the verification code to try again.","cordova-not-ready":"Cordova framework is not ready.","cors-unsupported":"This browser is not supported.","credential-already-in-use":"This credential is already associated with a different user account.","custom-token-mismatch":"The custom token corresponds to a different audience.","requires-recent-login":"This operation is sensitive and requires recent authentication. Log in again before retrying this request.",
"dynamic-link-not-activated":"Please activate Dynamic Links in the Firebase Console and agree to the terms and conditions.","email-already-in-use":"The email address is already in use by another account.","expired-action-code":"The action code has expired. ","cancelled-popup-request":"This operation has been cancelled due to another conflicting popup being opened.","internal-error":"An internal error has occurred.","invalid-app-credential":"The phone verification request contains an invalid application verifier. The reCAPTCHA token response is either invalid or expired.",
"invalid-app-id":"The mobile app identifier is not registed for the current project.","invalid-user-token":"The user's credential is no longer valid. The user must sign in again.","invalid-auth-event":"An internal error has occurred.","invalid-verification-code":"The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure use the verification code provided by the user.","invalid-cordova-configuration":"The following Cordova plugins must be installed to enable OAuth sign-in: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser and cordova-plugin-customurlscheme.",
"invalid-custom-token":"The custom token format is incorrect. Please check the documentation.","invalid-email":"The email address is badly formatted.","invalid-api-key":"Your API key is invalid, please check you have copied it correctly.","invalid-credential":"The supplied auth credential is malformed or has expired.","invalid-message-payload":"The email template corresponding to this action contains invalid characters in its message. Please fix by going to the Auth email templates section in the Firebase Console.",
"invalid-oauth-provider":"EmailAuthProvider is not supported for this operation. This operation only supports OAuth providers.","unauthorized-domain":"This domain is not authorized for OAuth operations for your Firebase project. Edit the list of authorized domains from the Firebase console.","invalid-action-code":"The action code is invalid. This can happen if the code is malformed, expired, or has already been used.","wrong-password":"The password is invalid or the user does not have a password.",
"invalid-phone-number":"The format of the phone number provided is incorrect. Please enter the phone number in a format that can be parsed into E.164 format. E.164 phone numbers are written in the format [+][country code][subscriber number including area code].","invalid-recipient-email":"The email corresponding to this action failed to send as the provided recipient email address is invalid.","invalid-sender":"The email template corresponding to this action contains an invalid sender email or name. Please fix by going to the Auth email templates section in the Firebase Console.",
"invalid-verification-id":"The verification ID used to create the phone auth credential is invalid.","missing-iframe-start":"An internal error has occurred.","auth-domain-config-required":"Be sure to include authDomain when calling firebase.initializeApp(), by following the instructions in the Firebase console.","missing-app-credential":"The phone verification request is missing an application verifier assertion. A reCAPTCHA response token needs to be provided.","missing-verification-code":"The phone auth credential was created with an empty SMS verification code.",
"missing-phone-number":"To send verification codes, provide a phone number for the recipient.","missing-verification-id":"The phone auth credential was created with an empty verification ID.","app-deleted":"This instance of FirebaseApp has been deleted.","account-exists-with-different-credential":"An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.","network-request-failed":"A network error (such as timeout, interrupted connection or unreachable host) has occurred.",
"no-auth-event":"An internal error has occurred.","no-such-provider":"User was not linked to an account with the given provider.","operation-not-allowed":"The given sign-in provider is disabled for this Firebase project. Enable it in the Firebase console, under the sign-in method tab of the Auth section.","operation-not-supported-in-this-environment":'This operation is not supported in the environment this application is running on. "location.protocol" must be http, https or chrome-extension and web storage must be enabled.',
"popup-blocked":"Unable to establish a connection with the popup. It may have been blocked by the browser.","popup-closed-by-user":"The popup has been closed by the user before finalizing the operation.","provider-already-linked":"User can only be linked to one identity for the given provider.","quota-exceeded":"The SMS quota for this project has been exceeded.","redirect-cancelled-by-user":"The redirect operation has been cancelled by the user before finalizing.","redirect-operation-pending":"A redirect sign-in operation is already pending.",
timeout:"The operation has timed out.","user-token-expired":"The user's credential is no longer valid. The user must sign in again.","too-many-requests":"We have blocked all requests from this device due to unusual activity. Try again later.","user-cancelled":"User did not grant your application the permissions it requested.","user-not-found":"There is no user record corresponding to this identifier. The user may have been deleted.","user-disabled":"The user account has been disabled by an administrator.",
"user-mismatch":"The supplied credentials do not correspond to the previously signed in user.","user-signed-out":"","weak-password":"The password must be 6 characters long or more.","web-storage-unsupported":"This browser is not supported or 3rd party cookies and data may be disabled."};var O=function(a,b,c,d,e){this.ha=a;this.R=b||null;this.vb=c||null;this.Cd=d||null;this.X=e||null;if(this.vb||this.X){if(this.vb&&this.X)throw new N("invalid-auth-event");if(this.vb&&!this.Cd)throw new N("invalid-auth-event");}else throw new N("invalid-auth-event");};O.prototype.oc=function(){return this.Cd};O.prototype.getError=function(){return this.X};O.prototype.F=function(){return{type:this.ha,eventId:this.R,urlResponse:this.vb,sessionId:this.Cd,error:this.X&&this.X.F()}};
var Zf=function(a){a=a||{};return a.type?new O(a.type,a.eventId,a.urlResponse,a.sessionId,a.error&&Yf(a.error)):null};var $f=function(a){var b="unauthorized-domain",c=void 0,d=Ze(a);a=d.ia;d=d.ka;"chrome-extension"==d?c=na("This chrome extension ID (chrome-extension://%s) is not authorized to run this operation. Add it to the OAuth redirect domains list in the Firebase console -> Auth section -> Sign in method tab.",a):"http"==d||"https"==d?c=na("This domain (%s) is not authorized to run this operation. Add it to the OAuth redirect domains list in the Firebase console -> Auth section -> Sign in method tab.",a):b=
"operation-not-supported-in-this-environment";N.call(this,b,c)};r($f,N);var ag=function(a){this.qf=a.sub;ma();this.mc=a.email||null};var bg=function(a,b){return a.then(function(a){if(a.idToken){a:{var c=a.idToken.split(".");if(3==c.length){for(var c=c[1],e=(4-c.length%4)%4,f=0;f<e;f++)c+=".";try{var g=JSON.parse(vb(c));if(g.sub&&g.iss&&g.aud&&g.exp){var l=new ag(g);break a}}catch(n){}}l=null}if(!l||b!=l.qf)throw new N("user-mismatch");return a}throw new N("user-mismatch");}).f(function(a){throw a&&a.code&&"auth/user-not-found"==a.code?new N("user-mismatch"):a;})},cg=function(a,b){if(b.idToken||b.accessToken)b.idToken&&M(this,"idToken",
b.idToken),b.accessToken&&M(this,"accessToken",b.accessToken);else if(b.oauthToken&&b.oauthTokenSecret)M(this,"accessToken",b.oauthToken),M(this,"secret",b.oauthTokenSecret);else throw new N("internal-error","failed to construct a credential");M(this,"providerId",a)};cg.prototype.Eb=function(a){return dg(a,eg(this))};cg.prototype.zc=function(a,b){var c=eg(this);c.idToken=b;return fg(a,c)};cg.prototype.od=function(a,b){var c=eg(this);return bg(gg(a,c),b)};
var eg=function(a){var b={};a.idToken&&(b.id_token=a.idToken);a.accessToken&&(b.access_token=a.accessToken);a.secret&&(b.oauth_token_secret=a.secret);b.providerId=a.providerId;return{postBody:df(b).toString(),requestUri:"http://localhost"}};cg.prototype.F=function(){var a={providerId:this.providerId};this.idToken&&(a.oauthIdToken=this.idToken);this.accessToken&&(a.oauthAccessToken=this.accessToken);this.secret&&(a.oauthTokenSecret=this.secret);return a};
var hg=function(a,b){this.Df=b||[];Of(this,{providerId:a,isOAuthProvider:!0});this.Td={}};hg.prototype.setCustomParameters=function(a){this.Td=Za(a);return this};var P=function(a){hg.call(this,a,Uf);this.Ad=[]};r(P,hg);P.prototype.addScope=function(a){Ka(this.Ad,a)||this.Ad.push(a);return this};P.prototype.ae=function(){return Pa(this.Ad)};
P.prototype.credential=function(a,b){if(!a&&!b)throw new N("argument-error","credential failed: must provide the ID token and/or the access token.");return new cg(this.providerId,{idToken:a||null,accessToken:b||null})};var ig=function(){P.call(this,"facebook.com")};r(ig,P);M(ig,"PROVIDER_ID","facebook.com");
var jg=function(a){if(!a)throw new N("argument-error","credential failed: expected 1 argument (the OAuth access token).");var b=a;ia(a)&&(b=a.accessToken);return(new ig).credential(null,b)},kg=function(){P.call(this,"github.com")};r(kg,P);M(kg,"PROVIDER_ID","github.com");
var lg=function(a){if(!a)throw new N("argument-error","credential failed: expected 1 argument (the OAuth access token).");var b=a;ia(a)&&(b=a.accessToken);return(new kg).credential(null,b)},mg=function(){P.call(this,"google.com");this.addScope("profile")};r(mg,P);M(mg,"PROVIDER_ID","google.com");var ng=function(a,b){var c=a;ia(a)&&(c=a.idToken,b=a.accessToken);return(new mg).credential(c,b)},og=function(){hg.call(this,"twitter.com",Tf)};r(og,hg);M(og,"PROVIDER_ID","twitter.com");
var pg=function(a,b){var c=a;ia(c)||(c={oauthToken:a,oauthTokenSecret:b});if(!c.oauthToken||!c.oauthTokenSecret)throw new N("argument-error","credential failed: expected 2 arguments (the OAuth access token and secret).");return new cg("twitter.com",c)},qg=function(a,b){this.mc=a;this.sd=b;M(this,"providerId","password")};qg.prototype.Eb=function(a){return Q(a,rg,{email:this.mc,password:this.sd})};qg.prototype.zc=function(a,b){return Q(a,sg,{idToken:b,email:this.mc,password:this.sd})};
qg.prototype.od=function(a,b){return bg(this.Eb(a),b)};qg.prototype.F=function(){return{email:this.mc,password:this.sd}};var tg=function(){Of(this,{providerId:"password",isOAuthProvider:!1})};Of(tg,{PROVIDER_ID:"password"});var ug=function(a){if(!(a.verificationId&&a.Tc||a.cc&&a.phoneNumber))throw new N("internal-error");this.M=a;M(this,"providerId","phone")};ug.prototype.Eb=function(a){return a.verifyPhoneNumber(vg(this))};ug.prototype.zc=function(a,b){var c=vg(this);c.idToken=b;return Q(a,wg,c)};
ug.prototype.od=function(a,b){var c=vg(this);c.operation="REAUTH";a=Q(a,xg,c);return bg(a,b)};ug.prototype.F=function(){var a={providerId:"phone"};this.M.verificationId&&(a.verificationId=this.M.verificationId);this.M.Tc&&(a.verificationCode=this.M.Tc);this.M.cc&&(a.temporaryProof=this.M.cc);this.M.phoneNumber&&(a.phoneNumber=this.M.phoneNumber);return a};
var vg=function(a){return a.M.cc&&a.M.phoneNumber?{temporaryProof:a.M.cc,phoneNumber:a.M.phoneNumber}:{sessionInfo:a.M.verificationId,code:a.M.Tc}},yg=function(a){try{this.Me=a||firebase.auth()}catch(b){throw new N("argument-error","Either an instance of firebase.auth.Auth must be passed as an argument to the firebase.auth.PhoneAuthProvider constructor, or the default firebase App instance must be initialized via firebase.initializeApp().");}Of(this,{providerId:"phone",isOAuthProvider:!1})};
yg.prototype.verifyPhoneNumber=function(a,b){var c=this.Me.g;return B(b.verify()).then(function(d){if(!m(d))throw new N("argument-error","An implementation of firebase.auth.ApplicationVerifier.prototype.verify() must return a firebase.Promise that resolves with a string.");switch(b.type){case "recaptcha":return Q(c,zg,{phoneNumber:a,recaptchaToken:d});default:throw new N("argument-error",'Only firebase.auth.ApplicationVerifiers with type="recaptcha" are currently supported.');}})};
var Ag=function(a,b){if(!a)throw new N("missing-verification-id");if(!b)throw new N("missing-verification-code");return new ug({verificationId:a,Tc:b})};Of(yg,{PROVIDER_ID:"phone"});
var Bg=function(a){if(a.temporaryProof&&a.phoneNumber)return new ug({cc:a.temporaryProof,phoneNumber:a.phoneNumber});var b=a&&a.providerId;if(!b||"password"===b)return null;var c=a&&a.oauthAccessToken,d=a&&a.oauthTokenSecret;a=a&&a.oauthIdToken;try{switch(b){case "google.com":return ng(a,c);case "facebook.com":return jg(c);case "github.com":return lg(c);case "twitter.com":return pg(c,d);default:return(new P(b)).credential(a,c)}}catch(e){return null}},Cg=function(a){if(!a.isOAuthProvider)throw new N("invalid-oauth-provider");
};var Dg=function(a,b,c){N.call(this,a,c);a=b||{};a.email&&M(this,"email",a.email);a.phoneNumber&&M(this,"phoneNumber",a.phoneNumber);a.credential&&M(this,"credential",a.credential)};r(Dg,N);Dg.prototype.F=function(){var a={code:this.code,message:this.message};this.email&&(a.email=this.email);this.phoneNumber&&(a.phoneNumber=this.phoneNumber);var b=this.credential&&this.credential.F();b&&ab(a,b);return a};Dg.prototype.toJSON=function(){return this.F()};
var Eg=function(a){if(a.code){var b=a.code||"";0==b.indexOf("auth/")&&(b=b.substring(5));var c={credential:Bg(a)};if(a.email)c.email=a.email;else if(a.phoneNumber)c.phoneNumber=a.phoneNumber;else return new N(b,a.message||void 0);return new Dg(b,c,a.message)}return null};var Fg=function(a){this.Wf=a};r(Fg,Ec);Fg.prototype.lc=function(){return new this.Wf};Fg.prototype.md=function(){return{}};
var R=function(a,b,c){var d="Node"==vf();d=k.XMLHttpRequest||d&&firebase.INTERNAL.node&&firebase.INTERNAL.node.XMLHttpRequest;if(!d)throw new N("internal-error","The XMLHttpRequest compatibility library was not found.");this.m=a;a=b||{};this.Jf=a.secureTokenEndpoint||"https://securetoken.googleapis.com/v1/token";this.Kf=a.secureTokenTimeout||Gg;this.se=Za(a.secureTokenHeaders||Hg);this.Ve=a.firebaseEndpoint||"https://www.googleapis.com/identitytoolkit/v3/relyingparty/";this.We=a.firebaseTimeout||
Ig;this.Zd=Za(a.firebaseHeaders||Jg);c&&(this.Zd["X-Client-Version"]=c,this.se["X-Client-Version"]=c);this.Ne=new Jc;this.Vf=new Fg(d)},Kg,Gg=new If(3E4,6E4),Hg={"Content-Type":"application/x-www-form-urlencoded"},Ig=new If(3E4,6E4),Jg={"Content-Type":"application/json"},Mg=function(a,b,c,d,e,f,g){Hf()?(kf()?a=q(a.Mf,a):(Kg||(Kg=new A(function(a,b){Lg(a,b)})),a=q(a.Lf,a)),a(b,c,d,e,f,g)):c&&c(null)};
R.prototype.Mf=function(a,b,c,d,e,f){var g="Node"==vf(),l=wf()?g?new F(this.Vf):new F:new F(this.Ne);if(f){l.ub=Math.max(0,f);var n=setTimeout(function(){l.dispatchEvent("timeout")},f)}l.listen("complete",function(){n&&clearTimeout(n);var a=null;try{a=JSON.parse(Le(this))||null}catch(lb){a=null}b&&b(a)});ec(l,"ready",function(){n&&clearTimeout(n);this.Ha||(this.Ha=!0,this.gb())});ec(l,"timeout",function(){n&&clearTimeout(n);this.Ha||(this.Ha=!0,this.gb());b&&b(null)});l.send(a,c,d,e)};
var Od="__fcb"+Math.floor(1E6*Math.random()).toString(),Lg=function(a,b){((window.gapi||{}).client||{}).request?a():(k[Od]=function(){((window.gapi||{}).client||{}).request?a():b(Error("CORS_UNSUPPORTED"))},Qd(function(){b(Error("CORS_UNSUPPORTED"))}))};
R.prototype.Lf=function(a,b,c,d,e){var f=this;Kg.then(function(){window.gapi.client.setApiKey(f.m);var g=window.gapi.auth.getToken();window.gapi.auth.setToken(null);window.gapi.client.request({path:a,method:c,body:d,headers:e,authType:"none",callback:function(a){window.gapi.auth.setToken(g);b&&b(a)}})}).f(function(a){b&&b({error:{message:a&&a.message||"CORS_UNSUPPORTED"}})})};
var Og=function(a,b){return new A(function(c,d){"refresh_token"==b.grant_type&&b.refresh_token||"authorization_code"==b.grant_type&&b.code?Mg(a,a.Jf+"?key="+encodeURIComponent(a.m),function(a){a?a.error?d(Ng(a)):a.access_token&&a.refresh_token?c(a):d(new N("internal-error")):d(new N("network-request-failed"))},"POST",df(b).toString(),a.se,a.Kf.get()):d(new N("internal-error"))})},Pg=function(a,b,c,d,e,f){var g=Ze(a.Ve+b);J(g,"key",a.m);f&&J(g,"cb",ma().toString());var l="GET"==c;if(l)for(var n in d)d.hasOwnProperty(n)&&
J(g,n,d[n]);return new A(function(b,f){Mg(a,g.toString(),function(a){a?a.error?f(Ng(a,e||{})):b(a):f(new N("network-request-failed"))},c,l?void 0:Ac(Df(d)),a.Zd,a.We.get())})},Qg=function(a){if(!kc.test(a.email))throw new N("invalid-email");},Rg=function(a){"email"in a&&Qg(a)},Tg=function(a,b){return Q(a,Sg,{identifier:b,continueUri:yf()?ff():"http://localhost"}).then(function(a){return a.allProviders||[]})},Vg=function(a){return Q(a,Ug,{}).then(function(a){return a.authorizedDomains||[]})},Wg=function(a){if(!a.idToken)throw new N("internal-error");
},Xg=function(a){if(a.phoneNumber||a.temporaryProof){if(!a.phoneNumber||!a.temporaryProof)throw new N("internal-error");}else{if(!a.sessionInfo)throw new N("missing-verification-id");if(!a.code)throw new N("missing-verification-code");}};R.prototype.signInAnonymously=function(){return Q(this,Yg,{})};R.prototype.updateEmail=function(a,b){return Q(this,Zg,{idToken:a,email:b})};R.prototype.updatePassword=function(a,b){return Q(this,sg,{idToken:a,password:b})};var $g={displayName:"DISPLAY_NAME",photoUrl:"PHOTO_URL"};
R.prototype.updateProfile=function(a,b){var c={idToken:a},d=[];Ua($g,function(a,f){var e=b[f];null===e?d.push(a):f in b&&(c[f]=e)});d.length&&(c.deleteAttribute=d);return Q(this,Zg,c)};R.prototype.sendPasswordResetEmail=function(a){return Q(this,ah,{requestType:"PASSWORD_RESET",email:a})};R.prototype.sendEmailVerification=function(a){return Q(this,bh,{requestType:"VERIFY_EMAIL",idToken:a})};R.prototype.verifyPhoneNumber=function(a){return Q(this,ch,a)};
var eh=function(a,b,c){return Q(a,dh,{idToken:b,deleteProvider:c})},fh=function(a){if(!a.requestUri||!a.sessionId&&!a.postBody)throw new N("internal-error");},gh=function(a){var b=null;a.needConfirmation?(a.code="account-exists-with-different-credential",b=Eg(a)):"FEDERATED_USER_ID_ALREADY_LINKED"==a.errorMessage?(a.code="credential-already-in-use",b=Eg(a)):"EMAIL_EXISTS"==a.errorMessage&&(a.code="email-already-in-use",b=Eg(a));if(b)throw b;if(!a.idToken)throw new N("internal-error");},dg=function(a,
b){b.returnIdpCredential=!0;return Q(a,hh,b)},fg=function(a,b){b.returnIdpCredential=!0;return Q(a,ih,b)},gg=function(a,b){b.returnIdpCredential=!0;b.autoCreate=!1;return Q(a,jh,b)},kh=function(a){if(!a.oobCode)throw new N("invalid-action-code");};R.prototype.confirmPasswordReset=function(a,b){return Q(this,lh,{oobCode:a,newPassword:b})};R.prototype.checkActionCode=function(a){return Q(this,mh,{oobCode:a})};R.prototype.applyActionCode=function(a){return Q(this,nh,{oobCode:a})};
var nh={endpoint:"setAccountInfo",C:kh,Ya:"email"},mh={endpoint:"resetPassword",C:kh,V:function(a){if(!a.email||!a.requestType)throw new N("internal-error");}},oh={endpoint:"signupNewUser",C:function(a){Qg(a);if(!a.password)throw new N("weak-password");},V:Wg,ta:!0},Sg={endpoint:"createAuthUri"},ph={endpoint:"deleteAccount",Wa:["idToken"]},dh={endpoint:"setAccountInfo",Wa:["idToken","deleteProvider"],C:function(a){if(!ga(a.deleteProvider))throw new N("internal-error");}},qh={endpoint:"getAccountInfo"},
bh={endpoint:"getOobConfirmationCode",Wa:["idToken","requestType"],C:function(a){if("VERIFY_EMAIL"!=a.requestType)throw new N("internal-error");},Ya:"email"},ah={endpoint:"getOobConfirmationCode",Wa:["requestType"],C:function(a){if("PASSWORD_RESET"!=a.requestType)throw new N("internal-error");Qg(a)},Ya:"email"},Ug={Md:!0,endpoint:"getProjectConfig",ee:"GET"},rh={Md:!0,endpoint:"getRecaptchaParam",ee:"GET",V:function(a){if(!a.recaptchaSiteKey)throw new N("internal-error");}},lh={endpoint:"resetPassword",
C:kh,Ya:"email"},zg={endpoint:"sendVerificationCode",Wa:["phoneNumber","recaptchaToken"],Ya:"sessionInfo"},Zg={endpoint:"setAccountInfo",Wa:["idToken"],C:Rg,ta:!0},sg={endpoint:"setAccountInfo",Wa:["idToken"],C:function(a){Rg(a);if(!a.password)throw new N("weak-password");},V:Wg,ta:!0},Yg={endpoint:"signupNewUser",V:Wg,ta:!0},hh={endpoint:"verifyAssertion",C:fh,V:gh,ta:!0},jh={endpoint:"verifyAssertion",C:fh,V:function(a){if(a.errorMessage&&"USER_NOT_FOUND"==a.errorMessage)throw new N("user-not-found");
if(!a.idToken)throw new N("internal-error");},ta:!0},ih={endpoint:"verifyAssertion",C:function(a){fh(a);if(!a.idToken)throw new N("internal-error");},V:gh,ta:!0},sh={endpoint:"verifyCustomToken",C:function(a){if(!a.token)throw new N("invalid-custom-token");},V:Wg,ta:!0},rg={endpoint:"verifyPassword",C:function(a){Qg(a);if(!a.password)throw new N("wrong-password");},V:Wg,ta:!0},ch={endpoint:"verifyPhoneNumber",C:Xg,V:Wg},wg={endpoint:"verifyPhoneNumber",C:function(a){if(!a.idToken)throw new N("internal-error");
Xg(a)},V:function(a){if(a.temporaryProof)throw a.code="credential-already-in-use",Eg(a);Wg(a)}},xg={Qe:{USER_NOT_FOUND:"user-not-found"},endpoint:"verifyPhoneNumber",C:Xg,V:Wg},Q=function(a,b,c){if(!Rf(c,b.Wa))return D(new N("internal-error"));var d=b.ee||"POST",e;return B(c).then(b.C).then(function(){b.ta&&(c.returnSecureToken=!0);return Pg(a,b.endpoint,d,c,b.Qe,b.Md||!1)}).then(function(a){return e=a}).then(b.V).then(function(){if(!b.Ya)return e;if(!(b.Ya in e))throw new N("internal-error");return e[b.Ya]})},
Ng=function(a,b){var c=(a.error&&a.error.errors&&a.error.errors[0]||{}).reason||"";var d={keyInvalid:"invalid-api-key",ipRefererBlocked:"app-not-authorized"};if(c=d[c]?new N(d[c]):null)return c;c=a.error&&a.error.message||"";d={INVALID_CUSTOM_TOKEN:"invalid-custom-token",CREDENTIAL_MISMATCH:"custom-token-mismatch",MISSING_CUSTOM_TOKEN:"internal-error",INVALID_IDENTIFIER:"invalid-email",MISSING_CONTINUE_URI:"internal-error",INVALID_EMAIL:"invalid-email",INVALID_PASSWORD:"wrong-password",USER_DISABLED:"user-disabled",
MISSING_PASSWORD:"internal-error",EMAIL_EXISTS:"email-already-in-use",PASSWORD_LOGIN_DISABLED:"operation-not-allowed",INVALID_IDP_RESPONSE:"invalid-credential",FEDERATED_USER_ID_ALREADY_LINKED:"credential-already-in-use",INVALID_MESSAGE_PAYLOAD:"invalid-message-payload",INVALID_RECIPIENT_EMAIL:"invalid-recipient-email",INVALID_SENDER:"invalid-sender",EMAIL_NOT_FOUND:"user-not-found",EXPIRED_OOB_CODE:"expired-action-code",INVALID_OOB_CODE:"invalid-action-code",MISSING_OOB_CODE:"internal-error",CREDENTIAL_TOO_OLD_LOGIN_AGAIN:"requires-recent-login",
INVALID_ID_TOKEN:"invalid-user-token",TOKEN_EXPIRED:"user-token-expired",USER_NOT_FOUND:"user-token-expired",CORS_UNSUPPORTED:"cors-unsupported",DYNAMIC_LINK_NOT_ACTIVATED:"dynamic-link-not-activated",INVALID_APP_ID:"invalid-app-id",TOO_MANY_ATTEMPTS_TRY_LATER:"too-many-requests",WEAK_PASSWORD:"weak-password",OPERATION_NOT_ALLOWED:"operation-not-allowed",USER_CANCELLED:"user-cancelled",CAPTCHA_CHECK_FAILED:"captcha-check-failed",INVALID_APP_CREDENTIAL:"invalid-app-credential",INVALID_CODE:"invalid-verification-code",
INVALID_PHONE_NUMBER:"invalid-phone-number",INVALID_SESSION_INFO:"invalid-verification-id",INVALID_TEMPORARY_PROOF:"invalid-credential",MISSING_APP_CREDENTIAL:"missing-app-credential",MISSING_CODE:"missing-verification-code",MISSING_PHONE_NUMBER:"missing-phone-number",MISSING_SESSION_INFO:"missing-verification-id",QUOTA_EXCEEDED:"quota-exceeded",SESSION_EXPIRED:"code-expired"};ab(d,b||{});b=(b=c.match(/^[^\s]+\s*:\s*(.*)$/))&&1<b.length?b[1]:void 0;for(var e in d)if(0===c.indexOf(e))return new N(d[e],
b);!b&&a&&(b=Cf(a));return new N("internal-error",b)};var th=function(a){this.aa=a};th.prototype.value=function(){return this.aa};th.prototype.ue=function(a){this.aa.style=a;return this};var uh=function(a){this.aa=a||{}};uh.prototype.value=function(){return this.aa};uh.prototype.ue=function(a){this.aa.style=a;return this};var wh=function(a){this.Tf=a;this.tc=null;this.qd=vh(this)},xh=function(a){var b=new uh;b.aa.where=document.body;b.aa.url=a.Tf;b.aa.messageHandlersFilter=L("gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER");b.aa.attributes=b.aa.attributes||{};(new th(b.aa.attributes)).ue({position:"absolute",top:"-100px",width:"1px",height:"1px"});b.aa.dontclear=!0;return b},vh=function(a){return yh().then(function(){return new A(function(b,c){L("gapi.iframes.getContext")().open(xh(a).value(),function(d){a.tc=d;a.tc.restyle({setHideOnLeave:!1});
var e=setTimeout(function(){c(Error("Network Error"))},zh.get()),f=function(){clearTimeout(e);b()};d.ping(f).then(f,function(){c(Error("Network Error"))})})})})};wh.prototype.sendMessage=function(a){var b=this;return this.qd.then(function(){return new A(function(c){b.tc.send(a.type,a,c,L("gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER"))})})};
var Ah=function(a,b){a.qd.then(function(){a.tc.register("authEvent",b,L("gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER"))})},Bh=new If(3E4,6E4),zh=new If(5E3,15E3),Ch=null,yh=function(){return Ch?Ch:Ch=(new A(function(a,b){if(Hf()){var c=function(){Gf();L("gapi.load")("gapi.iframes",{callback:a,ontimeout:function(){Gf();b(Error("Network Error"))},timeout:Bh.get()})};if(L("gapi.iframes.Iframe"))a();else if(L("gapi.load"))c();else{var d="__iframefcb"+Math.floor(1E6*Math.random()).toString();k[d]=function(){L("gapi.load")?
c():b(Error("Network Error"))};B(Nd("https://apis.google.com/js/api.js?onload="+d)).f(function(){b(Error("Network Error"))})}}else b(Error("Network Error"))})).f(function(a){Ch=null;throw a;})};var Dh=function(a,b,c){this.B=a;this.m=b;this.w=c;this.$a=null;this.fc=$e(this.B,"/__/auth/iframe");J(this.fc,"apiKey",this.m);J(this.fc,"appName",this.w)};Dh.prototype.Ed=function(a){this.$a=a;return this};Dh.prototype.toString=function(){this.$a?J(this.fc,"v",this.$a):this.fc.removeParameter("v");return this.fc.toString()};var Eh=function(a,b,c,d,e){this.B=a;this.m=b;this.w=c;this.Le=d;this.$a=this.R=this.yd=null;this.Ub=e};Eh.prototype.Ed=function(a){this.$a=a;return this};
Eh.prototype.toString=function(){var a=$e(this.B,"/__/auth/handler");J(a,"apiKey",this.m);J(a,"appName",this.w);J(a,"authType",this.Le);if(this.Ub.isOAuthProvider){J(a,"providerId",this.Ub.providerId);var b=this.Ub,c=Df(b.Td),d;for(d in c)c[d]=c[d].toString();b=b.Df;c=Za(c);for(d=0;d<b.length;d++){var e=b[d];e in c&&delete c[e]}Xa(c)||J(a,"customParameters",Cf(c))}"function"===typeof this.Ub.ae&&(b=this.Ub.ae(),b.length&&J(a,"scopes",b.join(",")));this.yd?J(a,"redirectUrl",this.yd):a.removeParameter("redirectUrl");
this.R?J(a,"eventId",this.R):a.removeParameter("eventId");this.$a?J(a,"v",this.$a):a.removeParameter("v");if(this.hc)for(var f in this.hc)this.hc.hasOwnProperty(f)&&!Ye(a,f)&&J(a,f,this.hc[f]);return a.toString()};
var Fh=function(a,b,c,d){this.B=a;this.m=b;this.w=c;this.Ye=(this.Fa=d||null)?xf(this.Fa):null;d=this.Fa;this.ff=(new Dh(a,b,c)).Ed(d).toString();this.na=[];this.g=new R(b,null,this.Ye);this.vc=this.xa=null},Gh=function(a){var b=ff();return Vg(a).then(function(a){a:{for(var c=Ze(b),e=c.ka,c=c.ia,f=0;f<a.length;f++){var g=a[f];var l=c;var n=e;0==g.indexOf("chrome-extension://")?l=Ze(g).ia==l&&"chrome-extension"==n:"http"!=n&&"https"!=n?l=!1:pf.test(g)?l=l==g:(g=g.split(".").join("\\."),l=(new RegExp("^(.+\\."+
g+"|"+g+")$","i")).test(l));if(l){a=!0;break a}}a=!1}if(!a)throw new $f(ff());})};h=Fh.prototype;h.Kb=function(){if(this.vc)return this.vc;var a=this;return this.vc=qf().then(function(){a.sc=new wh(a.ff);Hh(a)})};h.$b=function(a,b,c){var d=new N("popup-closed-by-user"),e=new N("web-storage-unsupported"),f=this,g=!1;return this.Na().then(function(){Ih(f).then(function(c){c||(a&&mf(a),b(e),g=!0)})}).f(function(){}).then(function(){if(!g)return of(a)}).then(function(){if(!g)return ve(c).then(function(){b(d)})})};
h.ve=function(){var a=K();return!Bf(a)&&!Ff(a)};h.de=function(){return!1};h.Sb=function(a,b,c,d,e,f,g){if(!a)return D(new N("popup-blocked"));if(g&&!Bf())return this.Na().f(function(b){mf(a);e(b)}),d(),B();this.xa||(this.xa=Gh(this.g));var l=this;return this.xa.then(function(){var b=l.Na().f(function(b){mf(a);e(b);throw b;});d();return b}).then(function(){Cg(c);if(!g){var d=Jh(l.B,l.m,l.w,b,c,null,f,l.Fa);gf(d,a)}}).f(function(a){"auth/network-request-failed"==a.code&&(l.xa=null);throw a;})};
h.Tb=function(a,b,c){this.xa||(this.xa=Gh(this.g));var d=this;return this.xa.then(function(){Cg(b);var e=Jh(d.B,d.m,d.w,a,b,ff(),c,d.Fa);gf(e)})};h.Na=function(){var a=this;return this.Kb().then(function(){return a.sc.qd}).f(function(){a.xa=null;throw new N("network-request-failed");})};h.ye=function(){return!0};
var Jh=function(a,b,c,d,e,f,g,l,n){a=new Eh(a,b,c,d,e);a.yd=f;a.R=g;f=a.Ed(l);f.hc=Za(n||null);return f.toString()},Hh=function(a){if(!a.sc)throw Error("IfcHandler must be initialized!");Ah(a.sc,function(b){var c={};if(b&&b.authEvent){var d=!1;b=Zf(b.authEvent);for(c=0;c<a.na.length;c++)d=a.na[c](b)||d;c={};c.status=d?"ACK":"ERROR";return B(c)}c.status="ERROR";return B(c)})},Ih=function(a){var b={type:"webStorageSupport"};return a.Kb().then(function(){return a.sc.sendMessage(b)}).then(function(a){if(a&&
a.length&&"undefined"!==typeof a[0].webStorageSupport)return a[0].webStorageSupport;throw Error();})};Fh.prototype.cb=function(a){this.na.push(a)};Fh.prototype.Yb=function(a){Na(this.na,function(b){return b==a})};var Kh=function(a,b,c){M(this,"type","recaptcha");this.Uc=this.zb=null;this.Bb=!1;this.Rd=a;this.ya=b||{theme:"light",type:"image"};this.G=[];if(this.ya.sitekey)throw new N("argument-error","sitekey should not be provided for reCAPTCHA as one is automatically provisioned for the current project.");this.wc="invisible"===this.ya.size;if(!ed(a)||!this.wc&&ed(a).hasChildNodes())throw new N("argument-error","reCAPTCHA container is either not found or already contains inner elements!");try{this.h=c||firebase.app()}catch(g){throw new N("argument-error",
"No firebase.app.App instance is currently initialized.");}if(this.h.options&&this.h.options.apiKey)a=firebase.SDK_VERSION?xf(firebase.SDK_VERSION):null,this.g=new R(this.h.options&&this.h.options.apiKey,null,a);else throw new N("invalid-api-key");var d=this;this.Pc=[];var e=this.ya.callback;this.ya.callback=function(a){d.Cb(a);if("function"===typeof e)e(a);else if("string"===typeof e){var b=L(e,k);"function"===typeof b&&b(a)}};var f=this.ya["expired-callback"];this.ya["expired-callback"]=function(){d.Cb(null);
if("function"===typeof f)f();else if("string"===typeof f){var a=L(f,k);"function"===typeof a&&a()}}};Kh.prototype.Cb=function(a){for(var b=0;b<this.Pc.length;b++)try{this.Pc[b](a)}catch(c){}};var Lh=function(a,b){Na(a.Pc,function(a){return a==b})};Kh.prototype.b=function(a){var b=this;this.G.push(a);wd(a,function(){Ma(b.G,a)});return a};
Kh.prototype.Lb=function(){var a=this;return this.zb?this.zb:this.zb=this.b(B().then(function(){if(yf())return qf();throw new N("operation-not-supported-in-this-environment","RecaptchaVerifier is only supported in a browser HTTP/HTTPS environment.");}).then(function(){return Nh()}).then(function(){return Q(a.g,rh,{})}).then(function(b){a.ya.sitekey=b.recaptchaSiteKey}).f(function(b){a.zb=null;throw b;}))};
Kh.prototype.render=function(){Oh(this);var a=this;return this.b(this.Lb().then(function(){if(null===a.Uc){var b=a.Rd;if(!a.wc){var c=ed(b),b=id("DIV");c.appendChild(b)}a.Uc=grecaptcha.render(b,a.ya)}return a.Uc}))};Kh.prototype.verify=function(){Oh(this);var a=this;return this.b(this.render().then(function(b){return new A(function(c){var d=grecaptcha.getResponse(b);if(d)c(d);else{var e=function(b){b&&(Lh(a,e),c(b))};a.Pc.push(e);a.wc&&grecaptcha.execute(a.Uc)}})}))};
var Oh=function(a){if(a.Bb)throw new N("internal-error","RecaptchaVerifier instance has been destroyed.");};Kh.prototype.clear=function(){Oh(this);this.Bb=!0;for(var a=0;a<this.G.length;a++)this.G[a].cancel("RecaptchaVerifier instance has been destroyed.");if(!this.wc)for(var a=ed(this.Rd),b;b=a.firstChild;)a.removeChild(b)};
var Nh=function(){return new A(function(a,b){if(Hf())if(k.grecaptcha)a();else{var c="__rcb"+Math.floor(1E6*Math.random()).toString();k[c]=function(){k.grecaptcha?a():b(new N("internal-error"));delete k[c]};B(Nd("https://www.google.com/recaptcha/api.js?onload="+c+"&render=explicit")).f(function(){b(new N("internal-error","Unable to load external reCAPTCHA dependencies!"))})}else b(new N("network-request-failed"))})};var Ph=function(a){this.D=a||firebase.INTERNAL.reactNative&&firebase.INTERNAL.reactNative.AsyncStorage;if(!this.D)throw new N("internal-error","The React Native compatibility library was not found.");};h=Ph.prototype;h.get=function(a){return B(this.D.getItem(a)).then(function(a){return a&&Ef(a)})};h.set=function(a,b){return B(this.D.setItem(a,Cf(b)))};h.remove=function(a){return B(this.D.removeItem(a))};h.eb=function(){};h.Va=function(){};var Qh=function(){this.D={}};h=Qh.prototype;h.get=function(a){return B(this.D[a])};h.set=function(a,b){this.D[a]=b;return B()};h.remove=function(a){delete this.D[a];return B()};h.eb=function(){};h.Va=function(){};var Sh=function(){if(!Rh()){if("Node"==vf())throw new N("internal-error","The LocalStorage compatibility library was not found.");throw new N("web-storage-unsupported");}this.D=k.localStorage||firebase.INTERNAL.node.localStorage},Rh=function(){var a="Node"==vf(),a=k.localStorage||a&&firebase.INTERNAL.node&&firebase.INTERNAL.node.localStorage;if(!a)return!1;try{return a.setItem("__sak","1"),a.removeItem("__sak"),!0}catch(b){return!1}};h=Sh.prototype;
h.get=function(a){var b=this;return B().then(function(){var c=b.D.getItem(a);return Ef(c)})};h.set=function(a,b){var c=this;return B().then(function(){var d=Cf(b);null===d?c.remove(a):c.D.setItem(a,d)})};h.remove=function(a){var b=this;return B().then(function(){b.D.removeItem(a)})};h.eb=function(a){k.window&&Xb(k.window,"storage",a)};h.Va=function(a){k.window&&fc(k.window,"storage",a)};var Th=function(){this.D={}};h=Th.prototype;h.get=function(){return B(null)};h.set=function(){return B()};h.remove=function(){return B()};h.eb=function(){};h.Va=function(){};var Vh=function(){if(!Uh()){if("Node"==vf())throw new N("internal-error","The SessionStorage compatibility library was not found.");throw new N("web-storage-unsupported");}this.D=k.sessionStorage||firebase.INTERNAL.node.sessionStorage},Uh=function(){var a="Node"==vf(),a=k.sessionStorage||a&&firebase.INTERNAL.node&&firebase.INTERNAL.node.sessionStorage;if(!a)return!1;try{return a.setItem("__sak","1"),a.removeItem("__sak"),!0}catch(b){return!1}};h=Vh.prototype;
h.get=function(a){var b=this;return B().then(function(){var c=b.D.getItem(a);return Ef(c)})};h.set=function(a,b){var c=this;return B().then(function(){var d=Cf(b);null===d?c.remove(a):c.D.setItem(a,d)})};h.remove=function(a){var b=this;return B().then(function(){b.D.removeItem(a)})};h.eb=function(){};h.Va=function(){};var Wh=function(a,b,c,d,e,f){if(!window.indexedDB)throw new N("web-storage-unsupported");this.Re=a;this.pd=b;this.bd=c;this.Ce=d;this.ab=e;this.$={};this.ac=[];this.Ob=0;this.gf=f||k.indexedDB},Xh,Yh=function(a){return new A(function(b,c){var d=a.gf.open(a.Re,a.ab);d.onerror=function(a){c(Error(a.target.errorCode))};d.onupgradeneeded=function(b){b=b.target.result;try{b.createObjectStore(a.pd,{keyPath:a.bd})}catch(f){c(f)}};d.onsuccess=function(a){b(a.target.result)}})},Zh=function(a){a.he||(a.he=
Yh(a));return a.he},$h=function(a,b){return b.objectStore(a.pd)},ai=function(a,b,c){return b.transaction([a.pd],c?"readwrite":"readonly")},bi=function(a){return new A(function(b,c){a.onsuccess=function(a){a&&a.target?b(a.target.result):b()};a.onerror=function(a){c(Error(a.target.errorCode))}})};h=Wh.prototype;
h.set=function(a,b){var c=!1,d,e=this;return wd(Zh(this).then(function(b){d=b;b=$h(e,ai(e,d,!0));return bi(b.get(a))}).then(function(f){var g=$h(e,ai(e,d,!0));if(f)return f.value=b,bi(g.put(f));e.Ob++;c=!0;f={};f[e.bd]=a;f[e.Ce]=b;return bi(g.add(f))}).then(function(){e.$[a]=b}),function(){c&&e.Ob--})};h.get=function(a){var b=this;return Zh(this).then(function(c){return bi($h(b,ai(b,c,!1)).get(a))}).then(function(a){return a&&a.value})};
h.remove=function(a){var b=!1,c=this;return wd(Zh(this).then(function(d){b=!0;c.Ob++;return bi($h(c,ai(c,d,!0))["delete"](a))}).then(function(){delete c.$[a]}),function(){b&&c.Ob--})};
h.Pf=function(){var a=this;return Zh(this).then(function(b){var c=$h(a,ai(a,b,!1));return c.getAll?bi(c.getAll()):new A(function(a,b){var d=[],e=c.openCursor();e.onsuccess=function(b){(b=b.target.result)?(d.push(b.value),b["continue"]()):a(d)};e.onerror=function(a){b(Error(a.target.errorCode))}})}).then(function(b){var c={},d=[];if(0==a.Ob){for(d=0;d<b.length;d++)c[b[d][a.bd]]=b[d][a.Ce];d=hf(a.$,c);a.$=c}return d})};h.eb=function(a){0==this.ac.length&&this.Gd();this.ac.push(a)};
h.Va=function(a){Na(this.ac,function(b){return b==a});0==this.ac.length&&this.Lc()};h.Gd=function(){var a=this;this.Lc();var b=function(){a.td=ve(800).then(q(a.Pf,a)).then(function(b){0<b.length&&w(a.ac,function(a){a(b)})}).then(b).f(function(a){"STOP_EVENT"!=a.message&&b()});return a.td};b()};h.Lc=function(){this.td&&this.td.cancel("STOP_EVENT")};var fi=function(){this.Vd={Browser:ci,Node:di,ReactNative:ei}[vf()]},gi,ci={U:Sh,Jd:Vh},di={U:Sh,Jd:Vh},ei={U:Ph,Jd:Th};var hi=function(a,b){this.Pe=b;M(this,"verificationId",a)};hi.prototype.confirm=function(a){a=Ag(this.verificationId,a);return this.Pe(a)};var ii=function(a,b,c,d){return(new yg(a)).verifyPhoneNumber(b,c).then(function(a){return new hi(a,d)})};var ji=function(a){var b={},c=a.email,d=a.newEmail;a=a.requestType;if(!c||!a)throw Error("Invalid provider user info!");b.fromEmail=d||null;b.email=c;M(this,"operation",a);M(this,"data",Sf(b))};var ki=function(a,b,c,d,e,f){this.yf=a;this.Ff=b;this.$e=c;this.Ac=d;this.Kd=e;this.Gf=!!f;this.mb=null;this.Oa=this.Ac;if(this.Kd<this.Ac)throw Error("Proactive refresh lower bound greater than upper bound!");};ki.prototype.start=function(){this.Oa=this.Ac;li(this,!0)};
var mi=function(a,b){if(b)return a.Oa=a.Ac,a.$e();b=a.Oa;a.Oa*=2;a.Oa>a.Kd&&(a.Oa=a.Kd);return b},li=function(a,b){a.stop();a.mb=ve(mi(a,b)).then(function(){return a.Gf?B():Kf()}).then(function(){return a.yf()}).then(function(){li(a,!0)}).f(function(b){a.Ff(b)&&li(a,!1)})};ki.prototype.stop=function(){this.mb&&(this.mb.cancel(),this.mb=null)};var si=function(a){var b={};b["facebook.com"]=ni;b["google.com"]=oi;b["github.com"]=pi;b["twitter.com"]=qi;var c=a&&a.providerId;return c?b[c]?new b[c](a):new ri(a):null},ri=function(a){var b=Ef(a.rawUserInfo||"{}");a=a.providerId;if(!a)throw Error("Invalid additional user info!");M(this,"profile",Sf(b||{}));M(this,"providerId",a)},ni=function(a){ri.call(this,a);if("facebook.com"!=this.providerId)throw Error("Invalid provider id!");};r(ni,ri);
var pi=function(a){ri.call(this,a);if("github.com"!=this.providerId)throw Error("Invalid provider id!");M(this,"username",this.profile&&this.profile.login||null)};r(pi,ri);var oi=function(a){ri.call(this,a);if("google.com"!=this.providerId)throw Error("Invalid provider id!");};r(oi,ri);var qi=function(a){ri.call(this,a);if("twitter.com"!=this.providerId)throw Error("Invalid provider id!");M(this,"username",a.screenName||null)};r(qi,ri);var ti=function(a,b,c,d){this.ke=a;this.Bd=b;this.Hf=c;this.Zb=d;this.S={};gi||(gi=new fi);a=gi;try{if(ef()){Xh||(Xh=new Wh("firebaseLocalStorageDb","firebaseLocalStorage","fbase_key","value",1));var e=Xh}else e=new a.Vd.U;this.Sa=e}catch(f){this.Sa=new Qh,this.Zb=!0}try{this.Nc=new a.Vd.Jd}catch(f){this.Nc=new Qh}this.Hd=q(this.we,this);this.$={}},ui,vi=function(){ui||(ui=new ti("firebase",":",!Ff(K())&&uf()?!0:!1,Bf()));return ui};h=ti.prototype;
h.Y=function(a,b){return this.ke+this.Bd+a.name+(b?this.Bd+b:"")};h.get=function(a,b){return(a.U?this.Sa:this.Nc).get(this.Y(a,b))};h.remove=function(a,b){b=this.Y(a,b);a.U&&!this.Zb&&(this.$[b]=null);return(a.U?this.Sa:this.Nc).remove(b)};h.set=function(a,b,c){var d=this.Y(a,c),e=this,f=a.U?this.Sa:this.Nc;return f.set(d,b).then(function(){return f.get(d)}).then(function(b){a.U&&!this.Zb&&(e.$[d]=b)})};
h.addListener=function(a,b,c){a=this.Y(a,b);this.Zb||(this.$[a]=k.localStorage.getItem(a));Xa(this.S)&&this.Gd();this.S[a]||(this.S[a]=[]);this.S[a].push(c)};h.removeListener=function(a,b,c){a=this.Y(a,b);this.S[a]&&(Na(this.S[a],function(a){return a==c}),0==this.S[a].length&&delete this.S[a]);Xa(this.S)&&this.Lc()};h.Gd=function(){this.Sa.eb(this.Hd);this.Zb||ef()||wi(this)};
var wi=function(a){xi(a);a.nd=setInterval(function(){for(var b in a.S){var c=k.localStorage.getItem(b),d=a.$[b];c!=d&&(a.$[b]=c,c=new Lb({type:"storage",key:b,target:window,oldValue:d,newValue:c,oe:!0}),a.we(c))}},1E3)},xi=function(a){a.nd&&(clearInterval(a.nd),a.nd=null)};ti.prototype.Lc=function(){this.Sa.Va(this.Hd);xi(this)};
ti.prototype.we=function(a){if(a&&a.Ze){var b=a.Ia.key;if(0==b.indexOf(this.ke+this.Bd)&&this.S[b]){"undefined"!==typeof a.Ia.oe?this.Sa.Va(this.Hd):xi(this);if(this.Hf){var c=k.localStorage.getItem(b),d=a.Ia.newValue;if(d!==c)null!==d?k.localStorage.setItem(b,d):k.localStorage.removeItem(b);else if(this.$[b]===d&&"undefined"===typeof a.Ia.oe)return}this.$[b]=k.localStorage.getItem(b);this.Od(b)}}else w(a,q(this.Od,this))};ti.prototype.Od=function(a){this.S[a]&&w(this.S[a],function(a){a()})};var yi=function(a,b){this.v=a;this.l=b||vi()},zi={name:"authEvent",U:!0},Ai=function(a){return a.l.get(zi,a.v).then(function(a){return Zf(a)})};yi.prototype.cb=function(a){this.l.addListener(zi,this.v,a)};yi.prototype.Yb=function(a){this.l.removeListener(zi,this.v,a)};var Bi=function(a){this.l=a||vi()},Ci={name:"sessionId",U:!1};Bi.prototype.oc=function(a){return this.l.get(Ci,a)};var Di=function(a,b,c,d,e,f){this.B=a;this.m=b;this.w=c;this.Fa=d||null;this.xe=b+":"+c;this.If=new Bi;this.$d=new yi(this.xe);this.ld=null;this.na=[];this.lf=e||500;this.Bf=f||2E3;this.Jb=this.Dc=null},Ei=function(a){return new N("invalid-cordova-configuration",a)};
Di.prototype.Na=function(){return this.Lb?this.Lb:this.Lb=sf().then(function(){if("function"!==typeof L("universalLinks.subscribe",k))throw Ei("cordova-universal-links-plugin is not installed");if("undefined"===typeof L("BuildInfo.packageName",k))throw Ei("cordova-plugin-buildinfo is not installed");if("function"!==typeof L("cordova.plugins.browsertab.openUrl",k))throw Ei("cordova-plugin-browsertab is not installed");if("function"!==typeof L("cordova.InAppBrowser.open",k))throw Ei("cordova-plugin-inappbrowser is not installed");
},function(){throw new N("cordova-not-ready");})};var Fi=function(){for(var a=20,b=[];0<a;)b.push("1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(Math.floor(62*Math.random()))),a--;return b.join("")},Gi=function(a){var b=new Gb;b.update(a);return Qa(b.digest())};h=Di.prototype;h.$b=function(a,b){b(new N("operation-not-supported-in-this-environment"));return B()};h.Sb=function(){return D(new N("operation-not-supported-in-this-environment"))};h.ye=function(){return!1};h.ve=function(){return!0};
h.de=function(){return!0};
h.Tb=function(a,b,c){if(this.Dc)return D(new N("redirect-operation-pending"));var d=this,e=k.document,f=null,g=null,l=null,n=null;return this.Dc=wd(B().then(function(){Cg(b);return Hi(d)}).then(function(){return Ii(d,a,b,c)}).then(function(){return(new A(function(a,b){g=function(){var b=L("cordova.plugins.browsertab.close",k);a();"function"===typeof b&&b();d.Jb&&"function"===typeof d.Jb.close&&(d.Jb.close(),d.Jb=null);return!1};d.cb(g);l=function(){f||(f=ve(d.Bf).then(function(){b(new N("redirect-cancelled-by-user"))}))};n=
function(){Jf()&&l()};e.addEventListener("resume",l,!1);K().toLowerCase().match(/android/)||e.addEventListener("visibilitychange",n,!1)})).f(function(a){return Ji(d).then(function(){throw a;})})}),function(){l&&e.removeEventListener("resume",l,!1);n&&e.removeEventListener("visibilitychange",n,!1);f&&f.cancel();g&&d.Yb(g);d.Dc=null})};
var Ii=function(a,b,c,d){var e=Fi(),f=new O(b,d,null,e,new N("no-auth-event")),g=L("BuildInfo.packageName",k);if("string"!==typeof g)throw new N("invalid-cordova-configuration");var l=L("BuildInfo.displayName",k),n={};if(K().toLowerCase().match(/iphone|ipad|ipod/))n.ibi=g;else if(K().toLowerCase().match(/android/))n.apn=g;else return D(new N("operation-not-supported-in-this-environment"));l&&(n.appDisplayName=l);e=Gi(e);n.sessionId=e;var C=Jh(a.B,a.m,a.w,b,c,null,d,a.Fa,n);return a.Na().then(function(){var b=
a.xe;return a.If.l.set(zi,f.F(),b)}).then(function(){var b=L("cordova.plugins.browsertab.isAvailable",k);if("function"!==typeof b)throw new N("invalid-cordova-configuration");var c=null;b(function(b){if(b){c=L("cordova.plugins.browsertab.openUrl",k);if("function"!==typeof c)throw new N("invalid-cordova-configuration");c(C)}else{c=L("cordova.InAppBrowser.open",k);if("function"!==typeof c)throw new N("invalid-cordova-configuration");b=c;var d=K();d=!(!d.match(/(iPad|iPhone|iPod).*OS 7_\d/i)&&!d.match(/(iPad|iPhone|iPod).*OS 8_\d/i));
a.Jb=b(C,d?"_blank":"_system","location=yes")}})})};Di.prototype.Cb=function(a){for(var b=0;b<this.na.length;b++)try{this.na[b](a)}catch(c){}};
var Hi=function(a){a.ld||(a.ld=a.Na().then(function(){return new A(function(b){var c=function(d){b(d);a.Yb(c);return!1};a.cb(c);Ki(a)})}));return a.ld},Ji=function(a){var b=null;return Ai(a.$d).then(function(c){b=c;c=a.$d;return c.l.remove(zi,c.v)}).then(function(){return b})},Ki=function(a){var b=L("universalLinks.subscribe",k);if("function"!==typeof b)throw new N("invalid-cordova-configuration");var c=new O("unknown",null,null,null,new N("no-auth-event")),d=!1,e=ve(a.lf).then(function(){return Ji(a).then(function(){d||
a.Cb(c)})}),f=function(b){d=!0;e&&e.cancel();Ji(a).then(function(d){var e=c;if(d&&b&&b.url){var e=null;var f=b.url;var g=Ze(f),l=Ye(g,"link"),n=Ye(Ze(l),"link"),g=Ye(g,"deep_link_id");f=Ye(Ze(g),"link")||g||n||l||f;-1!=f.indexOf("/__/auth/callback")&&(e=Ze(f),e=Ef(Ye(e,"firebaseError")||null),e=(e="object"===typeof e?Yf(e):null)?new O(d.ha,d.R,null,null,e):new O(d.ha,d.R,f,d.oc()));e=e||c}a.Cb(e)})},g=k.handleOpenURL;k.handleOpenURL=function(a){0==a.indexOf(L("BuildInfo.packageName",k)+"://")&&f({url:a});
if("function"===typeof g)try{g(a)}catch(n){console.error(n)}};b(null,f)};Di.prototype.cb=function(a){this.na.push(a);Hi(this).f(function(b){"auth/invalid-cordova-configuration"===b.code&&(b=new O("unknown",null,null,null,new N("no-auth-event")),a(b))})};Di.prototype.Yb=function(a){Na(this.na,function(b){return b==a})};var Li=function(a){this.v=a;this.l=vi()},Mi={name:"pendingRedirect",U:!1},Ni=function(a){return a.l.set(Mi,"pending",a.v)},Oi=function(a){return a.l.remove(Mi,a.v)},Pi=function(a){return a.l.get(Mi,a.v).then(function(a){return"pending"==a})};var Ti=function(a,b,c){this.B=a;this.m=b;this.w=c;this.bc=[];this.kb=!1;this.Yc=q(this.hd,this);this.Ta=new Qi(this);this.ud=new Ri(this);this.Pb=new Li(this.m+":"+this.w);this.Ba={};this.Ba.unknown=this.Ta;this.Ba.signInViaRedirect=this.Ta;this.Ba.linkViaRedirect=this.Ta;this.Ba.reauthViaRedirect=this.Ta;this.Ba.signInViaPopup=this.ud;this.Ba.linkViaPopup=this.ud;this.Ba.reauthViaPopup=this.ud;this.T=Si(this.B,this.m,this.w)},Si=function(a,b,c){var d=firebase.SDK_VERSION||null;return rf()?new Di(a,
b,c,d):new Fh(a,b,c,d)};Ti.prototype.reset=function(){this.kb=!1;this.T.Yb(this.Yc);this.T=Si(this.B,this.m,this.w)};Ti.prototype.Kb=function(){var a=this;this.kb||(this.kb=!0,this.T.cb(this.Yc));var b=this.T;return this.T.Na().f(function(c){a.T==b&&a.reset();throw c;})};var Wi=function(a){a.T.ve()&&a.Kb().f(function(b){var c=new O("unknown",null,null,null,new N("operation-not-supported-in-this-environment"));Ui(b)&&a.hd(c)});a.T.de()||Vi(a.Ta)};
Ti.prototype.subscribe=function(a){Ka(this.bc,a)||this.bc.push(a);if(!this.kb){var b=this;Pi(this.Pb).then(function(a){a?Oi(b.Pb).then(function(){b.Kb().f(function(a){var c=new O("unknown",null,null,null,new N("operation-not-supported-in-this-environment"));Ui(a)&&b.hd(c)})}):Wi(b)}).f(function(){Wi(b)})}};Ti.prototype.unsubscribe=function(a){Na(this.bc,function(b){return b==a})};
Ti.prototype.hd=function(a){if(!a)throw new N("invalid-auth-event");for(var b=!1,c=0;c<this.bc.length;c++){var d=this.bc[c];if(d.Pd(a.ha,a.R)){(b=this.Ba[a.ha])&&b.pe(a,d);b=!0;break}}Vi(this.Ta);return b};var Xi=new If(2E3,1E4),Yi=new If(3E4,6E4);Ti.prototype.getRedirectResult=function(){return this.Ta.getRedirectResult()};Ti.prototype.Sb=function(a,b,c,d,e){var f=this;return this.T.Sb(a,b,c,function(){f.kb||(f.kb=!0,f.T.cb(f.Yc))},function(){f.reset()},d,e)};
var Ui=function(a){return a&&"auth/cordova-not-ready"==a.code?!0:!1};Ti.prototype.Tb=function(a,b,c){var d=this,e;return Ni(this.Pb).then(function(){return d.T.Tb(a,b,c).f(function(a){if(Ui(a))throw new N("operation-not-supported-in-this-environment");e=a;return Oi(d.Pb).then(function(){throw e;})}).then(function(){return d.T.ye()?new A(function(){}):Oi(d.Pb).then(function(){return d.getRedirectResult()}).then(function(){}).f(function(){})})})};
Ti.prototype.$b=function(a,b,c,d){return this.T.$b(c,function(c){a.Xa(b,null,c,d)},Xi.get())};var Zi={},$i=function(a,b,c){var d=b+":"+c;Zi[d]||(Zi[d]=new Ti(a,b,c));return Zi[d]},Qi=function(a){this.l=a;this.sb=null;this.Wb=[];this.Vb=[];this.qb=null;this.xd=!1};Qi.prototype.reset=function(){this.sb=null;this.qb&&(this.qb.cancel(),this.qb=null)};
Qi.prototype.pe=function(a,b){if(!a)return D(new N("invalid-auth-event"));this.reset();this.xd=!0;var c=a.ha,d=a.R,e=a.getError()&&"auth/web-storage-unsupported"==a.getError().code,f=a.getError()&&"auth/operation-not-supported-in-this-environment"==a.getError().code;"unknown"!=c||e||f?a=a.X?this.vd(a,b):b.Db(c,d)?this.wd(a,b):D(new N("invalid-auth-event")):(aj(this,!1,null,null),a=B());return a};var Vi=function(a){a.xd||(a.xd=!0,aj(a,!1,null,null))};
Qi.prototype.vd=function(a){aj(this,!0,null,a.getError());return B()};Qi.prototype.wd=function(a,b){var c=this;b=b.Db(a.ha,a.R);var d=a.vb,e=a.oc(),f=!!a.ha.match(/Redirect$/);return b(d,e).then(function(a){aj(c,f,a,null)}).f(function(a){aj(c,f,null,a)})};
var bj=function(a,b){a.sb=function(){return D(b)};if(a.Vb.length)for(var c=0;c<a.Vb.length;c++)a.Vb[c](b)},cj=function(a,b){a.sb=function(){return B(b)};if(a.Wb.length)for(var c=0;c<a.Wb.length;c++)a.Wb[c](b)},aj=function(a,b,c,d){b?d?bj(a,d):cj(a,c):cj(a,{user:null});a.Wb=[];a.Vb=[]};Qi.prototype.getRedirectResult=function(){var a=this;return new A(function(b,c){a.sb?a.sb().then(b,c):(a.Wb.push(b),a.Vb.push(c),dj(a))})};
var dj=function(a){var b=new N("timeout");a.qb&&a.qb.cancel();a.qb=ve(Yi.get()).then(function(){a.sb||aj(a,!0,null,b)})},Ri=function(a){this.l=a};Ri.prototype.pe=function(a,b){if(!a)return D(new N("invalid-auth-event"));var c=a.ha,d=a.R;return a.X?this.vd(a,b):b.Db(c,d)?this.wd(a,b):D(new N("invalid-auth-event"))};Ri.prototype.vd=function(a,b){b.Xa(a.ha,null,a.getError(),a.R);return B()};
Ri.prototype.wd=function(a,b){var c=a.R,d=a.ha,e=b.Db(d,c),f=a.vb;a=a.oc();return e(f,a).then(function(a){b.Xa(d,a,null,c)}).f(function(a){b.Xa(d,null,a,c)})};var ej=function(a){this.g=a;this.Ca=this.ca=null;this.Ja=0};ej.prototype.F=function(){return{apiKey:this.g.m,refreshToken:this.ca,accessToken:this.Ca,expirationTime:this.Ja}};
var gj=function(a,b){var c=b.idToken,d=b.refreshToken;b=fj(b.expiresIn);a.Ca=c;a.Ja=b;a.ca=d},fj=function(a){return ma()+1E3*parseInt(a,10)},hj=function(a,b){return Og(a.g,b).then(function(b){a.Ca=b.access_token;a.Ja=fj(b.expires_in);a.ca=b.refresh_token;return{accessToken:a.Ca,expirationTime:a.Ja,refreshToken:a.ca}}).f(function(b){"auth/user-token-expired"==b.code&&(a.ca=null);throw b;})};
ej.prototype.getToken=function(a){a=!!a;return this.Ca&&!this.ca?D(new N("user-token-expired")):a||!this.Ca||ma()>this.Ja-3E4?this.ca?hj(this,{grant_type:"refresh_token",refresh_token:this.ca}):B(null):B({accessToken:this.Ca,expirationTime:this.Ja,refreshToken:this.ca})};var ij=function(a,b,c,d,e){Of(this,{uid:a,displayName:d||null,photoURL:e||null,email:c||null,providerId:b})},jj=function(a,b){Kb.call(this,a);for(var c in b)this[c]=b[c]};r(jj,Kb);
var S=function(a,b,c){this.G=[];this.m=a.apiKey;this.w=a.appName;this.B=a.authDomain||null;a=firebase.SDK_VERSION?xf(firebase.SDK_VERSION):null;this.g=new R(this.m,null,a);this.la=new ej(this.g);kj(this,b.idToken);gj(this.la,b);M(this,"refreshToken",this.la.ca);lj(this,c||{});je.call(this);this.Ec=!1;this.B&&Af()&&(this.s=$i(this.B,this.m,this.w));this.Kc=[];this.ma=null;this.ob=mj(this);this.xb=q(this.jd,this)};r(S,je);S.prototype.jd=function(){this.ob.mb&&(this.ob.stop(),this.ob.start())};
var nj=function(a){try{return firebase.app(a.w).auth()}catch(b){throw new N("internal-error","No firebase.auth.Auth instance is available for the Firebase App '"+a.w+"'!");}},mj=function(a){return new ki(function(){return a.getIdToken(!0)},function(a){return a&&"auth/network-request-failed"==a.code?!0:!1},function(){var b=a.la.Ja-ma()-3E5;return 0<b?b:0},3E4,96E4,!1)},oj=function(a){a.Bb||a.ob.mb||(a.ob.start(),fc(a,"tokenChanged",a.xb),Xb(a,"tokenChanged",a.xb))},pj=function(a){fc(a,"tokenChanged",
a.xb);a.ob.stop()},kj=function(a,b){a.ie=b;M(a,"_lat",b)},qj=function(a,b){Na(a.Kc,function(a){return a==b})},rj=function(a){for(var b=[],c=0;c<a.Kc.length;c++)b.push(a.Kc[c](a));return td(b).then(function(){return a})},sj=function(a){a.s&&!a.Ec&&(a.Ec=!0,a.s.subscribe(a))},lj=function(a,b){Of(a,{uid:b.uid,displayName:b.displayName||null,photoURL:b.photoURL||null,email:b.email||null,emailVerified:b.emailVerified||!1,phoneNumber:b.phoneNumber||null,isAnonymous:b.isAnonymous||!1,providerData:[]})};
M(S.prototype,"providerId","firebase");var tj=function(){},uj=function(a){return B().then(function(){if(a.Bb)throw new N("app-deleted");})},vj=function(a){return Ga(a.providerData,function(a){return a.providerId})},xj=function(a,b){b&&(wj(a,b.providerId),a.providerData.push(b))},wj=function(a,b){Na(a.providerData,function(a){return a.providerId==b})},yj=function(a,b,c){("uid"!=b||c)&&a.hasOwnProperty(b)&&M(a,b,c)};
S.prototype.copy=function(a){var b=this;b!=a&&(Of(this,{uid:a.uid,displayName:a.displayName,photoURL:a.photoURL,email:a.email,emailVerified:a.emailVerified,phoneNumber:a.phoneNumber,isAnonymous:a.isAnonymous,providerData:[]}),w(a.providerData,function(a){xj(b,a)}),this.la=a.la,M(this,"refreshToken",this.la.ca))};S.prototype.reload=function(){var a=this;return this.b(uj(this).then(function(){return zj(a).then(function(){return rj(a)}).then(tj)}))};
var zj=function(a){return a.getIdToken().then(function(b){var c=a.isAnonymous;return Aj(a,b).then(function(){c||yj(a,"isAnonymous",!1);return b})})};S.prototype.getIdToken=function(a){var b=this;return this.b(uj(this).then(function(){return b.la.getToken(a)}).then(function(a){if(!a)throw new N("internal-error");a.accessToken!=b.ie&&(kj(b,a.accessToken),b.Pa());yj(b,"refreshToken",a.refreshToken);return a.accessToken}))};
S.prototype.getToken=function(a){Lf["firebase.User.prototype.getToken is deprecated. Please use firebase.User.prototype.getIdToken instead."]||(Lf["firebase.User.prototype.getToken is deprecated. Please use firebase.User.prototype.getIdToken instead."]=!0,"undefined"!==typeof console&&"function"===typeof console.warn&&console.warn("firebase.User.prototype.getToken is deprecated. Please use firebase.User.prototype.getIdToken instead."));return this.getIdToken(a)};
var Bj=function(a,b){b.idToken&&a.ie!=b.idToken&&(gj(a.la,b),a.Pa(),kj(a,b.idToken),yj(a,"refreshToken",a.la.ca))};S.prototype.Pa=function(){this.dispatchEvent(new jj("tokenChanged"))};var Aj=function(a,b){return Q(a.g,qh,{idToken:b}).then(q(a.zf,a))};
S.prototype.zf=function(a){a=a.users;if(!a||!a.length)throw new N("internal-error");a=a[0];lj(this,{uid:a.localId,displayName:a.displayName,photoURL:a.photoUrl,email:a.email,emailVerified:!!a.emailVerified,phoneNumber:a.phoneNumber});for(var b=Cj(a),c=0;c<b.length;c++)xj(this,b[c]);yj(this,"isAnonymous",!(this.email&&a.passwordHash)&&!(this.providerData&&this.providerData.length))};
var Cj=function(a){return(a=a.providerUserInfo)&&a.length?Ga(a,function(a){return new ij(a.rawId,a.providerId,a.email,a.displayName,a.photoUrl)}):[]};S.prototype.reauthenticateAndRetrieveDataWithCredential=function(a){var b=this,c=null;return this.b(a.od(this.g,this.uid).then(function(a){Bj(b,a);c=Dj(b,a,"reauthenticate");b.ma=null;return b.reload()}).then(function(){return c}),!0)};S.prototype.reauthenticateWithCredential=function(a){return this.reauthenticateAndRetrieveDataWithCredential(a).then(function(){})};
var Ej=function(a,b){return zj(a).then(function(){if(Ka(vj(a),b))return rj(a).then(function(){throw new N("provider-already-linked");})})};S.prototype.linkAndRetrieveDataWithCredential=function(a){var b=this,c=null;return this.b(Ej(this,a.providerId).then(function(){return b.getIdToken()}).then(function(c){return a.zc(b.g,c)}).then(function(a){c=Dj(b,a,"link");return Fj(b,a)}).then(function(){return c}))};S.prototype.linkWithCredential=function(a){return this.linkAndRetrieveDataWithCredential(a).then(function(a){return a.user})};
S.prototype.linkWithPhoneNumber=function(a,b){var c=this;return this.b(Ej(this,"phone").then(function(){return ii(nj(c),a,b,q(c.linkAndRetrieveDataWithCredential,c))}))};S.prototype.reauthenticateWithPhoneNumber=function(a,b){var c=this;return this.b(B().then(function(){return ii(nj(c),a,b,q(c.reauthenticateAndRetrieveDataWithCredential,c))}),!0)};var Dj=function(a,b,c){var d=Bg(b);b=si(b);return Pf({user:a,credential:d,additionalUserInfo:b,operationType:c})},Fj=function(a,b){Bj(a,b);return a.reload().then(function(){return a})};
h=S.prototype;h.updateEmail=function(a){var b=this;return this.b(this.getIdToken().then(function(c){return b.g.updateEmail(c,a)}).then(function(a){Bj(b,a);return b.reload()}))};h.updatePhoneNumber=function(a){var b=this;return this.b(this.getIdToken().then(function(c){return a.zc(b.g,c)}).then(function(a){Bj(b,a);return b.reload()}))};h.updatePassword=function(a){var b=this;return this.b(this.getIdToken().then(function(c){return b.g.updatePassword(c,a)}).then(function(a){Bj(b,a);return b.reload()}))};
h.updateProfile=function(a){if(void 0===a.displayName&&void 0===a.photoURL)return uj(this);var b=this;return this.b(this.getIdToken().then(function(c){return b.g.updateProfile(c,{displayName:a.displayName,photoUrl:a.photoURL})}).then(function(a){Bj(b,a);yj(b,"displayName",a.displayName||null);yj(b,"photoURL",a.photoUrl||null);w(b.providerData,function(a){"password"===a.providerId&&(M(a,"displayName",b.displayName),M(a,"photoURL",b.photoURL))});return rj(b)}).then(tj))};
h.unlink=function(a){var b=this;return this.b(zj(this).then(function(c){return Ka(vj(b),a)?eh(b.g,c,[a]).then(function(a){var c={};w(a.providerUserInfo||[],function(a){c[a.providerId]=!0});w(vj(b),function(a){c[a]||wj(b,a)});c[yg.PROVIDER_ID]||M(b,"phoneNumber",null);return rj(b)}):rj(b).then(function(){throw new N("no-such-provider");})}))};
h["delete"]=function(){var a=this;return this.b(this.getIdToken().then(function(b){return Q(a.g,ph,{idToken:b})}).then(function(){a.dispatchEvent(new jj("userDeleted"))})).then(function(){for(var b=0;b<a.G.length;b++)a.G[b].cancel("app-deleted");a.G=[];a.Bb=!0;pj(a);M(a,"refreshToken",null);a.s&&a.s.unsubscribe(a)})};
h.Pd=function(a,b){return"linkViaPopup"==a&&(this.ga||null)==b&&this.fa||"reauthViaPopup"==a&&(this.ga||null)==b&&this.fa||"linkViaRedirect"==a&&(this.Aa||null)==b||"reauthViaRedirect"==a&&(this.Aa||null)==b?!0:!1};h.Xa=function(a,b,c,d){"linkViaPopup"!=a&&"reauthViaPopup"!=a||d!=(this.ga||null)||(c&&this.Ra?this.Ra(c):b&&!c&&this.fa&&this.fa(b),this.I&&(this.I.cancel(),this.I=null),delete this.fa,delete this.Ra)};
h.Db=function(a,b){return"linkViaPopup"==a&&b==(this.ga||null)?q(this.Xd,this):"reauthViaPopup"==a&&b==(this.ga||null)?q(this.Yd,this):"linkViaRedirect"==a&&(this.Aa||null)==b?q(this.Xd,this):"reauthViaRedirect"==a&&(this.Aa||null)==b?q(this.Yd,this):null};h.nc=function(){return zf(this.uid+":::")};h.linkWithPopup=function(a){var b=this;return Gj(this,"linkViaPopup",a,function(){return Ej(b,a.providerId).then(function(){return rj(b)})},!1)};
h.reauthenticateWithPopup=function(a){return Gj(this,"reauthViaPopup",a,function(){return B()},!0)};
var Gj=function(a,b,c,d,e){if(!Af())return D(new N("operation-not-supported-in-this-environment"));if(a.ma&&!e)return D(a.ma);var f=Wf(c.providerId),g=a.nc(),l=null;(!Bf()||uf())&&a.B&&c.isOAuthProvider&&(l=Jh(a.B,a.m,a.w,b,c,null,g,firebase.SDK_VERSION||null));var n=nf(l,f&&f.Rb,f&&f.Qb);d=d().then(function(){Hj(a);if(!e)return a.getIdToken().then(function(){})}).then(function(){return a.s.Sb(n,b,c,g,!!l)}).then(function(){return new A(function(c,d){a.Xa(b,null,new N("cancelled-popup-request"),a.ga||
null);a.fa=c;a.Ra=d;a.ga=g;a.I=a.s.$b(a,b,n,g)})}).then(function(a){n&&mf(n);return a?Pf(a):null}).f(function(a){n&&mf(n);throw a;});return a.b(d,e)};S.prototype.linkWithRedirect=function(a){var b=this;return Ij(this,"linkViaRedirect",a,function(){return Ej(b,a.providerId)},!1)};S.prototype.reauthenticateWithRedirect=function(a){return Ij(this,"reauthViaRedirect",a,function(){return B()},!0)};
var Ij=function(a,b,c,d,e){if(!Af())return D(new N("operation-not-supported-in-this-environment"));if(a.ma&&!e)return D(a.ma);var f=null,g=a.nc();d=d().then(function(){Hj(a);if(!e)return a.getIdToken().then(function(){})}).then(function(){a.Aa=g;return rj(a)}).then(function(b){a.Ua&&(b=a.Ua,b=b.l.set(Jj,a.F(),b.v));return b}).then(function(){return a.s.Tb(b,c,g)}).f(function(b){f=b;if(a.Ua)return Kj(a.Ua);throw f;}).then(function(){if(f)throw f;});return a.b(d,e)},Hj=function(a){if(!a.s||!a.Ec){if(a.s&&
!a.Ec)throw new N("internal-error");throw new N("auth-domain-config-required");}};S.prototype.Xd=function(a,b){var c=this;this.I&&(this.I.cancel(),this.I=null);var d=null,e=this.getIdToken().then(function(d){return fg(c.g,{requestUri:a,sessionId:b,idToken:d})}).then(function(a){d=Dj(c,a,"link");return Fj(c,a)}).then(function(){return d});return this.b(e)};
S.prototype.Yd=function(a,b){var c=this;this.I&&(this.I.cancel(),this.I=null);var d=null,e=B().then(function(){return bg(gg(c.g,{requestUri:a,sessionId:b}),c.uid)}).then(function(a){d=Dj(c,a,"reauthenticate");Bj(c,a);c.ma=null;return c.reload()}).then(function(){return d});return this.b(e,!0)};S.prototype.sendEmailVerification=function(){var a=this;return this.b(this.getIdToken().then(function(b){return a.g.sendEmailVerification(b)}).then(function(b){if(a.email!=b)return a.reload()}).then(function(){}))};
S.prototype.b=function(a,b){var c=this,d=Lj(this,a,b);this.G.push(d);wd(d,function(){Ma(c.G,d)});return d};var Lj=function(a,b,c){return a.ma&&!c?(b.cancel(),D(a.ma)):b.f(function(b){!b||"auth/user-disabled"!=b.code&&"auth/user-token-expired"!=b.code||(a.ma||a.dispatchEvent(new jj("userInvalidated")),a.ma=b);throw b;})};S.prototype.toJSON=function(){return this.F()};
S.prototype.F=function(){var a={uid:this.uid,displayName:this.displayName,photoURL:this.photoURL,email:this.email,emailVerified:this.emailVerified,phoneNumber:this.phoneNumber,isAnonymous:this.isAnonymous,providerData:[],apiKey:this.m,appName:this.w,authDomain:this.B,stsTokenManager:this.la.F(),redirectEventId:this.Aa||null};w(this.providerData,function(b){a.providerData.push(Qf(b))});return a};
var Mj=function(a){if(!a.apiKey)return null;var b={apiKey:a.apiKey,authDomain:a.authDomain,appName:a.appName},c={};if(a.stsTokenManager&&a.stsTokenManager.accessToken&&a.stsTokenManager.expirationTime)c.idToken=a.stsTokenManager.accessToken,c.refreshToken=a.stsTokenManager.refreshToken||null,c.expiresIn=(a.stsTokenManager.expirationTime-ma())/1E3;else return null;var d=new S(b,c,a);a.providerData&&w(a.providerData,function(a){a&&xj(d,Pf(a))});a.redirectEventId&&(d.Aa=a.redirectEventId);return d},
Nj=function(a,b,c){var d=new S(a,b);c&&(d.Ua=c);return d.reload().then(function(){return d})};var Oj=function(a){this.v=a;this.l=vi()},Jj={name:"redirectUser",U:!1},Kj=function(a){return a.l.remove(Jj,a.v)},Pj=function(a,b){return a.l.get(Jj,a.v).then(function(a){a&&b&&(a.authDomain=b);return Mj(a||{})})};var Qj=function(a){this.v=a;this.l=vi()},Rj={name:"authUser",U:!0},Sj=function(a,b){return a.l.set(Rj,b.F(),a.v)},Tj=function(a){return a.l.remove(Rj,a.v)},Uj=function(a,b){return a.l.get(Rj,a.v).then(function(a){a&&b&&(a.authDomain=b);return Mj(a||{})})};var T=function(a){this.Ga=!1;M(this,"app",a);if(this.h().options&&this.h().options.apiKey)a=firebase.SDK_VERSION?xf(firebase.SDK_VERSION):null,this.g=new R(this.h().options&&this.h().options.apiKey,null,a);else throw new N("invalid-api-key");this.G=[];this.Da=[];this.wb=[];this.vf=firebase.INTERNAL.createSubscribe(q(this.hf,this));this.gc=void 0;this.xf=firebase.INTERNAL.createSubscribe(q(this.kf,this));Vj(this,null);a=this.h().options.apiKey;var b=this.h().name;this.ua=new Qj(a+":"+b);a=this.h().options.apiKey;
b=this.h().name;this.rb=new Oj(a+":"+b);this.ic=this.b(Wj(this));this.ra=this.b(Xj(this));this.xc=!1;this.gd=q(this.Of,this);this.Be=q(this.ib,this);this.xb=q(this.jd,this);this.ze=q(this.df,this);this.Ae=q(this.ef,this);Yj(this);this.INTERNAL={};this.INTERNAL["delete"]=q(this["delete"],this);this.Ka=0};T.prototype.toJSON=function(){return{apiKey:this.h().options.apiKey,authDomain:this.h().options.authDomain,appName:this.h().name,currentUser:U(this)&&U(this).F()}};
var Zj=function(a){return a.Se||D(new N("auth-domain-config-required"))},Yj=function(a){var b=a.h().options.authDomain,c=a.h().options.apiKey;b&&Af()&&(a.Se=a.ic.then(function(){if(!a.Ga)return a.s=$i(b,c,a.h().name),a.s.subscribe(a),U(a)&&sj(U(a)),a.Xb&&(sj(a.Xb),a.Xb=null),a.s}))};h=T.prototype;h.Pd=function(a,b){switch(a){case "unknown":case "signInViaRedirect":return!0;case "signInViaPopup":return this.ga==b&&!!this.fa;default:return!1}};
h.Xa=function(a,b,c,d){"signInViaPopup"==a&&this.ga==d&&(c&&this.Ra?this.Ra(c):b&&!c&&this.fa&&this.fa(b),this.I&&(this.I.cancel(),this.I=null),delete this.fa,delete this.Ra)};h.Db=function(a,b){return"signInViaRedirect"==a||"signInViaPopup"==a&&this.ga==b&&this.fa?q(this.Ue,this):null};
h.Ue=function(a,b){var c=this;a={requestUri:a,sessionId:b};this.I&&(this.I.cancel(),this.I=null);var d=null,e=null,f=dg(c.g,a).then(function(a){d=Bg(a);e=si(a);return a});a=c.ic.then(function(){return f}).then(function(a){return ak(c,a)}).then(function(){return Pf({user:U(c),credential:d,additionalUserInfo:e,operationType:"signIn"})});return this.b(a)};h.nc=function(){return zf()};
h.signInWithPopup=function(a){if(!Af())return D(new N("operation-not-supported-in-this-environment"));var b=this,c=Wf(a.providerId),d=this.nc(),e=null;(!Bf()||uf())&&this.h().options.authDomain&&a.isOAuthProvider&&(e=Jh(this.h().options.authDomain,this.h().options.apiKey,this.h().name,"signInViaPopup",a,null,d,firebase.SDK_VERSION||null));var f=nf(e,c&&c.Rb,c&&c.Qb),c=Zj(this).then(function(b){return b.Sb(f,"signInViaPopup",a,d,!!e)}).then(function(){return new A(function(a,c){b.Xa("signInViaPopup",
null,new N("cancelled-popup-request"),b.ga);b.fa=a;b.Ra=c;b.ga=d;b.I=b.s.$b(b,"signInViaPopup",f,d)})}).then(function(a){f&&mf(f);return a?Pf(a):null}).f(function(a){f&&mf(f);throw a;});return this.b(c)};h.signInWithRedirect=function(a){if(!Af())return D(new N("operation-not-supported-in-this-environment"));var b=this,c=Zj(this).then(function(){return b.s.Tb("signInViaRedirect",a)});return this.b(c)};
h.getRedirectResult=function(){if(!Af())return D(new N("operation-not-supported-in-this-environment"));var a=this,b=Zj(this).then(function(){return a.s.getRedirectResult()}).then(function(a){return a?Pf(a):null});return this.b(b)};
var ak=function(a,b){var c={};c.apiKey=a.h().options.apiKey;c.authDomain=a.h().options.authDomain;c.appName=a.h().name;return a.ic.then(function(){return Nj(c,b,a.rb)}).then(function(b){if(U(a)&&b.uid==U(a).uid)return U(a).copy(b),a.ib(b);Vj(a,b);sj(b);return a.ib(b)}).then(function(){a.Pa()})},Vj=function(a,b){U(a)&&(qj(U(a),a.Be),fc(U(a),"tokenChanged",a.xb),fc(U(a),"userDeleted",a.ze),fc(U(a),"userInvalidated",a.Ae),pj(U(a)));b&&(b.Kc.push(a.Be),Xb(b,"tokenChanged",a.xb),Xb(b,"userDeleted",a.ze),
Xb(b,"userInvalidated",a.Ae),0<a.Ka&&oj(b));M(a,"currentUser",b)};T.prototype.signOut=function(){var a=this,b=this.ra.then(function(){if(!U(a))return B();Vj(a,null);return Tj(a.ua).then(function(){a.Pa()})});return this.b(b)};
var bk=function(a){var b=a.h().options.authDomain,b=Pj(a.rb,b).then(function(b){if(a.Xb=b)b.Ua=a.rb;return Kj(a.rb)});return a.b(b)},Wj=function(a){var b=a.h().options.authDomain,c=bk(a).then(function(){return Uj(a.ua,b)}).then(function(b){return b?(b.Ua=a.rb,a.Xb&&(a.Xb.Aa||null)==(b.Aa||null)?b:b.reload().then(function(){return Sj(a.ua,b).then(function(){return b})}).f(function(c){return"auth/network-request-failed"==c.code?b:Tj(a.ua)})):null}).then(function(b){Vj(a,b||null)});return a.b(c)},Xj=
function(a){return a.ic.then(function(){return a.getRedirectResult()}).f(function(){}).then(function(){if(!a.Ga)return a.gd()}).f(function(){}).then(function(){if(!a.Ga){a.xc=!0;var b=a.ua;b.l.addListener(Rj,b.v,a.gd)}})};h=T.prototype;
h.Of=function(){var a=this,b=this.h().options.authDomain;return Uj(this.ua,b).then(function(b){if(!a.Ga){var c;if(c=U(a)&&b){c=U(a).uid;var e=b.uid;c=void 0===c||null===c||""===c||void 0===e||null===e||""===e?!1:c==e}if(c)return U(a).copy(b),U(a).getIdToken();if(U(a)||b)Vj(a,b),b&&(sj(b),b.Ua=a.rb),a.s&&a.s.subscribe(a),a.Pa()}})};h.ib=function(a){return Sj(this.ua,a)};h.jd=function(){this.Pa();this.ib(U(this))};h.df=function(){this.signOut()};h.ef=function(){this.signOut()};
var ck=function(a,b){var c=null,d=null;return a.b(b.then(function(b){c=Bg(b);d=si(b);return ak(a,b)}).then(function(){return Pf({user:U(a),credential:c,additionalUserInfo:d,operationType:"signIn"})}))};h=T.prototype;h.hf=function(a){var b=this;this.addAuthTokenListener(function(){a.next(U(b))})};h.kf=function(a){var b=this;dk(this,function(){a.next(U(b))})};
h.onIdTokenChanged=function(a,b,c){var d=this;this.xc&&firebase.Promise.resolve().then(function(){p(a)?a(U(d)):p(a.next)&&a.next(U(d))});return this.vf(a,b,c)};h.onAuthStateChanged=function(a,b,c){var d=this;this.xc&&firebase.Promise.resolve().then(function(){d.gc=d.getUid();p(a)?a(U(d)):p(a.next)&&a.next(U(d))});return this.xf(a,b,c)};h.getIdToken=function(a){var b=this,c=this.ra.then(function(){return U(b)?U(b).getIdToken(a).then(function(a){return{accessToken:a}}):null});return this.b(c)};
h.signInWithCustomToken=function(a){var b=this;return this.ra.then(function(){return ck(b,Q(b.g,sh,{token:a}))}).then(function(a){a=a.user;yj(a,"isAnonymous",!1);return b.ib(a)}).then(function(){return U(b)})};h.signInWithEmailAndPassword=function(a,b){var c=this;return this.ra.then(function(){return ck(c,Q(c.g,rg,{email:a,password:b}))}).then(function(a){return a.user})};h.createUserWithEmailAndPassword=function(a,b){var c=this;return this.ra.then(function(){return ck(c,Q(c.g,oh,{email:a,password:b}))}).then(function(a){return a.user})};
h.signInWithCredential=function(a){return this.signInAndRetrieveDataWithCredential(a).then(function(a){return a.user})};h.signInAndRetrieveDataWithCredential=function(a){var b=this;return this.ra.then(function(){return ck(b,a.Eb(b.g))})};h.signInAnonymously=function(){var a=this;return this.ra.then(function(){var b=U(a);return b&&b.isAnonymous?b:ck(a,a.g.signInAnonymously()).then(function(b){b=b.user;yj(b,"isAnonymous",!0);return a.ib(b)}).then(function(){return U(a)})})};h.h=function(){return this.app};
var U=function(a){return a.currentUser};T.prototype.getUid=function(){return U(this)&&U(this).uid||null};var ek=function(a){return U(a)&&U(a)._lat||null};h=T.prototype;h.Pa=function(){if(this.xc){for(var a=0;a<this.Da.length;a++)if(this.Da[a])this.Da[a](ek(this));if(this.gc!==this.getUid()&&this.wb.length)for(this.gc=this.getUid(),a=0;a<this.wb.length;a++)if(this.wb[a])this.wb[a](ek(this))}};h.Ke=function(a){this.addAuthTokenListener(a);this.Ka++;0<this.Ka&&U(this)&&oj(U(this))};
h.Cf=function(a){var b=this;w(this.Da,function(c){c==a&&b.Ka--});0>this.Ka&&(this.Ka=0);0==this.Ka&&U(this)&&pj(U(this));this.removeAuthTokenListener(a)};h.addAuthTokenListener=function(a){var b=this;this.Da.push(a);this.b(this.ra.then(function(){b.Ga||Ka(b.Da,a)&&a(ek(b))}))};h.removeAuthTokenListener=function(a){Na(this.Da,function(b){return b==a})};var dk=function(a,b){a.wb.push(b);a.b(a.ra.then(function(){!a.Ga&&Ka(a.wb,b)&&a.gc!==a.getUid()&&(a.gc=a.getUid(),b(ek(a)))}))};h=T.prototype;
h["delete"]=function(){this.Ga=!0;for(var a=0;a<this.G.length;a++)this.G[a].cancel("app-deleted");this.G=[];this.ua&&(a=this.ua,a.l.removeListener(Rj,a.v,this.gd));this.s&&this.s.unsubscribe(this);return firebase.Promise.resolve()};h.b=function(a){var b=this;this.G.push(a);wd(a,function(){Ma(b.G,a)});return a};h.fetchProvidersForEmail=function(a){return this.b(Tg(this.g,a))};h.verifyPasswordResetCode=function(a){return this.checkActionCode(a).then(function(a){return a.data.email})};
h.confirmPasswordReset=function(a,b){return this.b(this.g.confirmPasswordReset(a,b).then(function(){}))};h.checkActionCode=function(a){return this.b(this.g.checkActionCode(a).then(function(a){return new ji(a)}))};h.applyActionCode=function(a){return this.b(this.g.applyActionCode(a).then(function(){}))};h.sendPasswordResetEmail=function(a){return this.b(this.g.sendPasswordResetEmail(a).then(function(){}))};
h.signInWithPhoneNumber=function(a,b){return this.b(ii(this,a,b,q(this.signInAndRetrieveDataWithCredential,this)))};var fk="First Second Third Fourth Fifth Sixth Seventh Eighth Ninth".split(" "),V=function(a,b){return{name:a||"",N:"a valid string",optional:!!b,O:m}},gk=function(){return{name:"opt_forceRefresh",N:"a boolean",optional:!0,O:ba}},W=function(a,b){return{name:a||"",N:"a valid object",optional:!!b,O:ia}},hk=function(a,b){return{name:a||"",N:"a function",optional:!!b,O:p}},ik=function(){return{name:"",N:"null",optional:!1,O:fa}},jk=function(){return{name:"",N:"an HTML element",optional:!1,O:function(a){return!!(a&&
a instanceof Element)}}},kk=function(){return{name:"auth",N:"an instance of Firebase Auth",optional:!0,O:function(a){return!!(a&&a instanceof T)}}},lk=function(){return{name:"app",N:"an instance of Firebase App",optional:!0,O:function(a){return!!(a&&a instanceof firebase.app.App)}}},mk=function(a){return{name:a?a+"Credential":"credential",N:a?"a valid "+a+" credential":"a valid credential",optional:!1,O:function(b){if(!b)return!1;var c=!a||b.providerId===a;return!(!b.Eb||!c)}}},nk=function(){return{name:"authProvider",
N:"a valid Auth provider",optional:!1,O:function(a){return!!(a&&a.providerId&&a.hasOwnProperty&&a.hasOwnProperty("isOAuthProvider"))}}},ok=function(){return{name:"applicationVerifier",N:"an implementation of firebase.auth.ApplicationVerifier",optional:!1,O:function(a){return!!(a&&m(a.type)&&p(a.verify))}}},X=function(a,b,c,d){return{name:c||"",N:a.N+" or "+b.N,optional:!!d,O:function(c){return a.O(c)||b.O(c)}}};var Y=function(a,b){for(var c in b){var d=b[c].name;a[d]=pk(d,a[c],b[c].a)}},Z=function(a,b,c,d){a[b]=pk(b,c,d)},pk=function(a,b,c){if(!c)return b;var d=qk(a);a=function(){var a=Array.prototype.slice.call(arguments);a:{var e=Array.prototype.slice.call(a);var l=0;for(var n=!1,C=0;C<c.length;C++)if(c[C].optional)n=!0;else{if(n)throw new N("internal-error","Argument validator encountered a required argument after an optional argument.");l++}n=c.length;if(e.length<l||n<e.length)e="Expected "+(l==n?1==
l?"1 argument":l+" arguments":l+"-"+n+" arguments")+" but got "+e.length+".";else{for(l=0;l<e.length;l++)if(n=c[l].optional&&void 0===e[l],!c[l].O(e[l])&&!n){e=c[l];if(0>l||l>=fk.length)throw new N("internal-error","Argument validator received an unsupported number of arguments.");e=fk[l]+" argument "+(e.name?'"'+e.name+'" ':"")+"must be "+e.N+".";break a}e=null}}if(e)throw new N("argument-error",d+" failed: "+e);return b.apply(this,a)};for(var e in b)a[e]=b[e];for(e in b.prototype)a.prototype[e]=
b.prototype[e];return a},qk=function(a){a=a.split(".");return a[a.length-1]};Y(T.prototype,{applyActionCode:{name:"applyActionCode",a:[V("code")]},checkActionCode:{name:"checkActionCode",a:[V("code")]},confirmPasswordReset:{name:"confirmPasswordReset",a:[V("code"),V("newPassword")]},createUserWithEmailAndPassword:{name:"createUserWithEmailAndPassword",a:[V("email"),V("password")]},fetchProvidersForEmail:{name:"fetchProvidersForEmail",a:[V("email")]},getRedirectResult:{name:"getRedirectResult",a:[]},onAuthStateChanged:{name:"onAuthStateChanged",a:[X(W(),hk(),"nextOrObserver"),
hk("opt_error",!0),hk("opt_completed",!0)]},onIdTokenChanged:{name:"onIdTokenChanged",a:[X(W(),hk(),"nextOrObserver"),hk("opt_error",!0),hk("opt_completed",!0)]},sendPasswordResetEmail:{name:"sendPasswordResetEmail",a:[V("email")]},signInAndRetrieveDataWithCredential:{name:"signInAndRetrieveDataWithCredential",a:[mk()]},signInAnonymously:{name:"signInAnonymously",a:[]},signInWithCredential:{name:"signInWithCredential",a:[mk()]},signInWithCustomToken:{name:"signInWithCustomToken",a:[V("token")]},signInWithEmailAndPassword:{name:"signInWithEmailAndPassword",
a:[V("email"),V("password")]},signInWithPhoneNumber:{name:"signInWithPhoneNumber",a:[V("phoneNumber"),ok()]},signInWithPopup:{name:"signInWithPopup",a:[nk()]},signInWithRedirect:{name:"signInWithRedirect",a:[nk()]},signOut:{name:"signOut",a:[]},toJSON:{name:"toJSON",a:[V(null,!0)]},verifyPasswordResetCode:{name:"verifyPasswordResetCode",a:[V("code")]}});
Y(S.prototype,{"delete":{name:"delete",a:[]},getIdToken:{name:"getIdToken",a:[gk()]},getToken:{name:"getToken",a:[gk()]},linkAndRetrieveDataWithCredential:{name:"linkAndRetrieveDataWithCredential",a:[mk()]},linkWithCredential:{name:"linkWithCredential",a:[mk()]},linkWithPhoneNumber:{name:"linkWithPhoneNumber",a:[V("phoneNumber"),ok()]},linkWithPopup:{name:"linkWithPopup",a:[nk()]},linkWithRedirect:{name:"linkWithRedirect",a:[nk()]},reauthenticateAndRetrieveDataWithCredential:{name:"reauthenticateAndRetrieveDataWithCredential",
a:[mk()]},reauthenticateWithCredential:{name:"reauthenticateWithCredential",a:[mk()]},reauthenticateWithPhoneNumber:{name:"reauthenticateWithPhoneNumber",a:[V("phoneNumber"),ok()]},reauthenticateWithPopup:{name:"reauthenticateWithPopup",a:[nk()]},reauthenticateWithRedirect:{name:"reauthenticateWithRedirect",a:[nk()]},reload:{name:"reload",a:[]},sendEmailVerification:{name:"sendEmailVerification",a:[]},toJSON:{name:"toJSON",a:[V(null,!0)]},unlink:{name:"unlink",a:[V("provider")]},updateEmail:{name:"updateEmail",
a:[V("email")]},updatePassword:{name:"updatePassword",a:[V("password")]},updatePhoneNumber:{name:"updatePhoneNumber",a:[mk("phone")]},updateProfile:{name:"updateProfile",a:[W("profile")]}});Y(A.prototype,{f:{name:"catch"},then:{name:"then"}});Y(hi.prototype,{confirm:{name:"confirm",a:[V("verificationCode")]}});Z(tg,"credential",function(a,b){return new qg(a,b)},[V("email"),V("password")]);Y(ig.prototype,{addScope:{name:"addScope",a:[V("scope")]},setCustomParameters:{name:"setCustomParameters",a:[W("customOAuthParameters")]}});
Z(ig,"credential",jg,[X(V(),W(),"token")]);Y(kg.prototype,{addScope:{name:"addScope",a:[V("scope")]},setCustomParameters:{name:"setCustomParameters",a:[W("customOAuthParameters")]}});Z(kg,"credential",lg,[X(V(),W(),"token")]);Y(mg.prototype,{addScope:{name:"addScope",a:[V("scope")]},setCustomParameters:{name:"setCustomParameters",a:[W("customOAuthParameters")]}});Z(mg,"credential",ng,[X(V(),X(W(),ik()),"idToken"),X(V(),ik(),"accessToken",!0)]);
Y(og.prototype,{setCustomParameters:{name:"setCustomParameters",a:[W("customOAuthParameters")]}});Z(og,"credential",pg,[X(V(),W(),"token"),V("secret",!0)]);Y(P.prototype,{addScope:{name:"addScope",a:[V("scope")]},credential:{name:"credential",a:[X(V(),ik(),"idToken",!0),X(V(),ik(),"accessToken",!0)]},setCustomParameters:{name:"setCustomParameters",a:[W("customOAuthParameters")]}});Z(yg,"credential",Ag,[V("verificationId"),V("verificationCode")]);
Y(yg.prototype,{verifyPhoneNumber:{name:"verifyPhoneNumber",a:[V("phoneNumber"),ok()]}});Y(N.prototype,{toJSON:{name:"toJSON",a:[V(null,!0)]}});Y(Dg.prototype,{toJSON:{name:"toJSON",a:[V(null,!0)]}});Y($f.prototype,{toJSON:{name:"toJSON",a:[V(null,!0)]}});Y(Kh.prototype,{clear:{name:"clear",a:[]},render:{name:"render",a:[]},verify:{name:"verify",a:[]}});
(function(){if("undefined"!==typeof firebase&&firebase.INTERNAL&&firebase.INTERNAL.registerService){var a={Auth:T,Error:N};Z(a,"EmailAuthProvider",tg,[]);Z(a,"FacebookAuthProvider",ig,[]);Z(a,"GithubAuthProvider",kg,[]);Z(a,"GoogleAuthProvider",mg,[]);Z(a,"TwitterAuthProvider",og,[]);Z(a,"OAuthProvider",P,[V("providerId")]);Z(a,"PhoneAuthProvider",yg,[kk()]);Z(a,"RecaptchaVerifier",Kh,[X(V(),jk(),"recaptchaContainer"),W("recaptchaParameters",!0),lk()]);firebase.INTERNAL.registerService("auth",function(a,
c){a=new T(a);c({INTERNAL:{getUid:q(a.getUid,a),getToken:q(a.getIdToken,a),addAuthTokenListener:q(a.Ke,a),removeAuthTokenListener:q(a.Cf,a)}});return a},a,function(a,c){if("create"===a)try{c.auth()}catch(d){}});firebase.INTERNAL.extendNamespace({User:S})}else throw Error("Cannot find the firebase namespace; be sure to include firebase-app.js before this library.");})();}).call(this);
